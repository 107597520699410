import { Injectable } from '@angular/core';
import {
  FileUrls,
  EmailUrls,
  ConversationUrls,
  ThemeUrls,
  EcmUrls,
  OfferUrls,
} from '@core/constants';
import { ApiClient } from '@core/services/api-client.http.service';
import { Observable } from 'rxjs';
import { AttachmentModel } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class FileApiService {
  constructor(private api: ApiClient) {}

  setAttachment(
    file: number[],
    fileType: string,
    templateId?: number,
    importType?: string
  ): Observable<AttachmentModel[]> {
    switch (fileType) {
      case 'Email':
        return this.api.post(EmailUrls.attachments, file);
      case 'Html':
        return this.api.post(EmailUrls.layout, file);
      case 'BrandingImage':
        return this.api.put(ThemeUrls.themeImage, file);
      case 'Xls':
        {
          // if (templateId != null) {
          //   return this.api.post(ConversationUrls.conversationImports + '?templateId=' + templateId, file);
          // }
          // else {
          //   return this.api.post(ConversationUrls.conversationImports, file);
          // }
          let queryParams = '';
          if (templateId) {
            queryParams += `?templateId=${templateId}`;
          }
          if (importType) {
            queryParams += (queryParams ? '&' : '?') + `importType=${importType}`;
          }

          return this.api.post(
            ConversationUrls.conversationImports + queryParams,
            file
          );

        }
      case 'Ecm':
        return this.api.post(EcmUrls.uploadFile, file);
      case 'BannerImage':
        return this.api.post(OfferUrls.files, file);
      case 'OfferXls':
        return this.api.post(OfferUrls.offerImports, file);
      default:
        return this.api.post(FileUrls.files, file);
    }
  }

  downloadFile(url: string, request?: any): Observable<Blob> {
    return this.api.getFile(url, null, request);
  }

  removeDefaultEmailTemplateLayout(): Observable<any> {
    return this.api.delete(EmailUrls.defaultLayout);
  }
}
