import { formateUTCtoTZ } from '@shared/utils';
import { UserGroupManagementGroupsDto } from '@core/services/dto/user-group-management-groups.dto';


export class UserGroupManagementGroupsModel {
  createdBy: string;
  description: string;
  id: number;
  lastModified: string;
  lastModifiedBy: string;
  name: string;
  status: string;
  isHidden?: boolean;
  groupType?: string;
  type?: string;

  constructor(dto: UserGroupManagementGroupsDto) {
    for (const prop in dto) {
      if (dto.hasOwnProperty(prop)) {
        this[prop] = dto[prop];
      }
    }
    this.createdBy = `${dto.createdBy?.firstName} ${dto.createdBy?.lastName}`;
    this.lastModified = dto.lastModified ? formateUTCtoTZ(dto.lastModified) : '';
    this.lastModifiedBy = `${dto.lastModifiedBy?.firstName} ${dto.lastModifiedBy?.lastName}`;
  }
}
