export class ConfirmationDialogModel {
  constructor(
    public title: string,
    public message: string,
    public submitButton: string,
    public showCancelBtn = true,
    public cancelButtonText = 'Cancel',
    public borderCancelBtn = false,
    public buttonsGap = '40px',
    public isHtmlContent = false
  ) { }
}
