import { Injectable } from '@angular/core';
import { AuthService } from '@core/services/auth';
import { PermissionEnum, PermissionStringEnum } from '@shared/enums';
import { RoleEnum } from '@shared/enums/role.enum';

@Injectable({
  providedIn: 'root'
})

export class AccessService {
  constructor(
    private auth: AuthService
  ) { }

  checkUserPermissions(permissions: PermissionStringEnum[]): boolean {
    return this.auth.permissions.some(p => permissions.includes(PermissionStringEnum[PermissionEnum[p]]));
  }

  checkUserRoles(roles: RoleEnum[]): boolean {
    return this.auth.roles.some(r => roles.includes(r));
  }

  isCrmUser(): boolean {
    return this.auth.roles.some(r => r === RoleEnum.CrmAdmin || r === RoleEnum.CrmUser);
  }

  isAosUser(): boolean {
   return this.auth.roles.some(r => r !== RoleEnum.CrmAdmin && r !== RoleEnum.CrmUser);
  }
}
