import { TaskTemplateDto } from '@core/services/dto';

export class WorkflowTaskModel {
  id: number;
  position: number;
  fromTaskTemplateId: number;
  dependencyId?: number;
  dependency?: {
    position: number,
    description: string
  };
  type: string;
  description: string;
  taskStart: {
    dueIn: number,
    dueDateType: string
  };
  taskCompletion: {
    dueIn: number,
    dueDateType: string
  };
  dueDate?: string;
  status?: string;
  assigneeId?: {
    firstName: string,
    lastName: string,
    id: string,
    name: string,
    isGroup: boolean,
    status: string,
    type: string,
    groupName: string
  };
  taskTemplateName?: string;
  priority?: string;
}

export class WorkflowTaskAddUpdateModel {
  id: number;
  position: number;
  fromTaskTemplateId: number;
  dependencyId?: number;
  dependency?: {
    position: number;
    description: string;
  };
  type: string;
  description: string;
  taskStart: {
    dueIn: number;
    dueDateType: string;
  };
  taskCompletion: {
    dueIn: number;
    dueDateType: string;
  };
  dueDate?: string;
  status?: string;
  assigneeId?: string;
}
