import { Observable } from 'rxjs';
import { ApiClient } from '@core/services/api-client.http.service';
import { Injectable } from '@angular/core';
import { CanadaPostAddressUrls } from '@core/constants';
import { CanadaPostAddressFindDto } from '@core/services/dto/canada-post-address-find.dto';
import { CanadaPostAddressRetrieveDto } from '@core/services/dto/canada-post-address-retrieve.dto';

@Injectable({
  providedIn: 'root',
})
export class CanadaPostAddressApiService {
  constructor(private api: ApiClient) { }

  searchAddress(searchAddr: string, id: string, IsGoogleActivated: boolean, countryCode: string): Observable<CanadaPostAddressFindDto[]> {
    return this.api.get(CanadaPostAddressUrls.search(searchAddr, id, IsGoogleActivated, countryCode));
  }

  retrieveAddress(id: string, IsGoogleActivated: boolean, countryCode: string): Observable<CanadaPostAddressRetrieveDto[]> {
    return this.api.get(CanadaPostAddressUrls.get(id, IsGoogleActivated, countryCode));
  }
}
