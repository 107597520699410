import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getCurrentAzureFunctionKey } from '@config/org.config';
import { KeyVaultUrls } from '@core/constants';
import { ApiClient } from '@core/services/api-client.http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class KeyVaultApiService {
  constructor(private api: ApiClient) {
  }

  getWeatherApiKey(): Observable<string> {
    return this.api.get(KeyVaultUrls.keyVaultValue('WeatherWidgetAPIKey'), null, this.buildHeaders());
  }

  getSqlBuilderUsageKey(): Observable<boolean> {
    return this.api.get(KeyVaultUrls.keyVaultValue('SqlBuilderUsageKey'), null, this.buildHeaders());
  }

  getThemeBuilderTypeKey(): Observable<string> {
    return this.api.get(KeyVaultUrls.keyVaultValue('ThemeBuilderTypeKey'), null, this.buildHeaders());
  }

  getECMUsageKey(): Observable<boolean> {
    return this.api.get(KeyVaultUrls.keyVaultValue('ECMUsageKey'), null, this.buildHeaders());
  }

  getUmbrellaVersion(): Observable<string> {
    return this.api.get(KeyVaultUrls.keyVaultValue('EnvironmentName'), null, this.buildHeaders());
  }

  getPendoApiKey(): Observable<string> {
    return this.api.get(KeyVaultUrls.keyVaultValue('PendoUsageKey'), null, this.buildHeaders());
  }

  private buildHeaders(): HttpHeaders {
    return new HttpHeaders({
      'x-functions-key': getCurrentAzureFunctionKey()
    });
  }
}
