import { Injector, Pipe, PipeTransform, Type } from '@angular/core';
import { of } from 'rxjs';

@Pipe({
  name: 'dynamicPipe'
})
export class DynamicPipe implements PipeTransform {

  public constructor(private injector: Injector) {
  }

  transform(value: any, requiredPipe: Type<any>, pipeArgs: any): any {
    if (!requiredPipe && pipeArgs) {
      return of(value + ' ' + pipeArgs);
    }
    if (!requiredPipe) {
      return of(value);
    }

    const injector = Injector.create({
      name: 'DynamicPipe',
      parent: this.injector,
      providers: [
        { provide: requiredPipe }
      ]
    });
    const pipe = injector.get(requiredPipe);
    return pipe.transform(value, pipeArgs);
  }
}
