export enum CommunicationTypeEnum {
  Mail,
  Phone,
  InPerson,
  SMS,
  All,
  None
}

export const CommunicationTypeStringEnum = {
  Mail: 'E-mail',
  Phone: 'Phone call',
  InPerson: 'In Person',
  SMS: 'SMS',
  All: 'All',
  None: 'None'
};
