import { autoUnsubscribeMixin } from '@core/helpers/auto-unsubscribe.mixin';
import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CreateConversationStateService } from '@shared/components/conversation/create-conversation.state.service';
import { SearchAccountOptionsModel, SearchMemberOptionsModel } from '@shared/models';
import { SearchMemberProspectOptionsModel } from '@shared/models/search.member.prospect.options.model';

@Component({
  selector: 'app-add-link-to-conversation',
  templateUrl: './add-link-to-conversation.component.html'
})
export class AddLinkToConversationComponent extends autoUnsubscribeMixin() implements OnInit {
  @Output() linkAdded: EventEmitter<SearchMemberProspectOptionsModel[]> = new EventEmitter();
  @Input() conversationType: any;
  preselectedMember: SearchMemberProspectOptionsModel;
  preselectedAccount: SearchAccountOptionsModel[];

  constructor(private state: CreateConversationStateService) {
    super();
  }

  ngOnInit(): void {
    this.state.member$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        data => {
          if (data) {
            this.preselectedMember = new SearchMemberProspectOptionsModel(data);
            if (data.isProspectInfo === false) {
              this.preselectedMember.type = 'member';
            } else if (data.isProspectInfo === true) {
              this.preselectedMember.type = 'prospect';
            }
          }
        }
      );
    this.state.preselectedAccount$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        data => {
          if (data) {
            this.preselectedAccount = data;
          }
        }
      );
  }

  addInstance(links: SearchMemberProspectOptionsModel[]): void {
    this.linkAdded.emit(links);
  }
}
