import { Injectable } from '@angular/core';
import { ApiClient } from '@core/services/api-client.http.service';
import { Observable } from 'rxjs';
import { VersionDetailDto, CurrentUmbrellaVersionDto } from '@core/services/dto';
import { VersioningUrls } from '@core/constants/endpoints.constants';
@Injectable({
  providedIn: 'any'
})
export class VersioningApiService {

  constructor(private api: ApiClient) { }

  getUmbrellaVersionDetail(currentUmbrellaVersion: string): Observable<VersionDetailDto> {
    return this.api.get<VersionDetailDto>(VersioningUrls.versioningDetails(currentUmbrellaVersion));
  }
  getUmbrellaVersion(environmentName: string): Observable<CurrentUmbrellaVersionDto> {
    return this.api.get<CurrentUmbrellaVersionDto>(VersioningUrls.currentUmbrellaVersion(environmentName));
  }
}
