<div class="root"
     fxLayout>
  <div class="sidebar-left"
       [ngClass.lt-lg]="'tablet'">
    <app-sidebar-left></app-sidebar-left>
  </div>
  <div class="center-col"
       fxFlex>
      <div class="header">
        <app-main-header></app-main-header>
      </div>
      <div class="content">
        <div class="main-col">
          <router-outlet></router-outlet>
        </div>
        <div>
          <router-outlet name="right-sidebar-outlet"></router-outlet>
        </div>
      </div>
  </div>
</div>
