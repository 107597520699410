import { LinkModel } from '@shared/models/index';
import { TasksDto } from '@core/services/dto';

export class SearchTaskOptionsModel {
  label: string;
  ref: string;
  type: string;
  value: string;
  links?: LinkModel[];

  constructor(dto: any) {
    this.label = `${dto.type} - ${dto.description}`;
    this.value = dto.description;
    this.ref = dto.id.toString();
    this.type = '';
    this.links = dto.links;
  }
}
