<div [ngClass.lt-lg]="'tablet'"
     class="sticky-wrap">
  <div class="left-arrow-wrap"
       [class.show]="menuExpanded"
       (click)="closeMenu()">
    <div class="left-arrow"></div>
    <div class="left-arrow-circle"></div>
    <span>Minimize</span>
  </div>
  <div class="content"
       (click)="openMenu()"
       [class.expanded]="menuExpanded">
    <div class="right-arrow-wrap">
      <div class="right-arrow"></div>
      <div class="right-arrow-circle"></div>
    </div>
    <div class="logo"
         [ngStyle]="{'background-image': menuExpanded ? 'url(' + logoFullUrl + ')' :'url(' + logoSmallUrl + ')'}">
    </div>
    <div class="custom-menu-wrapper">
      <ul class="menu" [ngClass]="{'main-menu':minimizeModals.length}">
        <li class="menu-item {{item.class}}"
            [class.active]="item.url === baseUrl || item.label === baseUrl"
            *ngFor="let item of menuItems; let i = index"
            [class.empty]="(isItemVisible$(item) | async) === false || !item?.notEmpty">
          <a (click)="!isItemDisabled(item) && goToUrl(item.url, item.label, item.newWindow, item, null, $event)"
            [class.disabled]="isItemDisabled(item)"
            *ngIf="(isItemVisible$(item) | async) && item?.notEmpty">
            <div fxLayout="row"
                fxLayoutAlign="space-between center">
              <div>
                <div class="icon"><i class="icon-{{item.class}}"></i></div>
                <div class="title">{{item.label}}</div>
              </div>
              <div class="caret-container"
                  *ngIf="item.child_items && menuExpanded" (click)="collapse(); $event.stopPropagation();"
                  [ngClass]="(item.url === baseUrl || item.label === baseUrl) ? 'arrow-up' : 'arrow-down'">
                <div class="arrow"></div>
                <div class="arrow-circle"></div>
              </div>
            </div>
          </a>
          <div class="submenu"
              *ngIf="item.child_items">
            <div class="submenu-item"
                (click)="closeMenu($event)"
                [class.active]="submenu_item.url === currentUrl"
                *ngFor="let submenu_item of item.child_items">
              <div *ngIf="hasAccess$(submenu_item, item) | async">
                <div *ngIf="submenu_item.sub_child_items">
                  <div class="submenu-title nested {{submenu_item.isView ? 'selected' : ''}}"
                      (click)="submenu_item.isView = !submenu_item.isView; $event.stopPropagation();">
                    {{submenu_item.label}}
                    <span class="caret-container"
                          [ngClass]="submenu_item.isView ? 'arrow-up' : 'arrow-down'">
                      <span class="arrow"></span>
                      <span class="arrow-circle"></span>
                    </span>
                  </div>
                  <div *ngIf="submenu_item.isView">
                    <div *ngFor="let child of submenu_item.sub_child_items">
                      <a class="sub-child-item link" *ngIf="hasAccess$(child, submenu_item) | async"
                        (click)="goToUrl(child.url, child.label, child.newWindow, item, submenu_item, $event)"
                        fxLayout="row">{{child.label}}</a>
                    </div>
                  </div>
                </div>
                <a *ngIf="!submenu_item.sub_child_items"
                  class="submenu-title link"
                  (click)="goToUrl(submenu_item.url, submenu_item.label, submenu_item.newWindow, item, submenu_item, $event)"
                  fxLayout="row">{{submenu_item.label}}</a>
              </div>
            </div>
          </div>
        </li>
      </ul>
      
      <div class="dialog-actions-wrapper" *ngIf="minimizeModals.length">
        <hr [ngClass]="{'collapsed-line':!menuExpanded, 'expanded-line':menuExpanded}">
        <ul class="menu dialog-actions">
          <li class="menu-item"  *ngFor="let dialog of minimizeModals">
            <a (click)="openDialog(dialog,$event)">
              <div fxLayout="row"
                  fxLayoutAlign="space-between center">
                <div>
                  <div class="icon"><i class="{{ dialog.icon }}"></i></div>
                  <div class="title"> {{ dialog.title }} </div>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
    
    <div class="menu-bottom">
      <div class="build-version-title"
           *ngIf="menuExpanded">Version:</div>
      <div class="build-version-value"><a (click)="openVersionPage('/version-detail')">{{umbrellaVersion}}</a></div>
    </div>
  </div>
</div>