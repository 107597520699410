import { SearchMemberOptionsModel } from '@shared/models/search.member.options.model';

export class EcmFileModel {
  name: string;
  description: string;
  fileMetadataId: number;
  folderId: number;
  tags: number[];
  typeId?: number;
  subTypeId?: number;
  links?: SearchMemberOptionsModel[];
}
