import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AsappServiceUrls } from '@core/constants';
import { ApiClient } from '@core/services/api-client.http.service';
import { ApplicationsRequestDto } from '@shared/models/requests/applications.request.dto';
import { ApplicationsDto, PaginatedResponseDto } from '@core/services/dto';
import { ApplicationStatusCountDto } from '@core/services/dto/application-status-count.dto';
import { ApplicationStatusesRequestDto } from '@shared/models/requests/application-statuses.request.dto';
import { SiteDetailDto } from '@core/services/dto/site-detail.dto';
import { UserBranchDto } from '../dto/user-Branch.dto';
@Injectable({
  providedIn: 'root',
})
export class ApplicationsApiService {
  constructor(private api: ApiClient) {}

  getApplications(
    request: ApplicationsRequestDto
  ): Observable<PaginatedResponseDto<ApplicationsDto>> {
    return this.api.get<PaginatedResponseDto<ApplicationsDto>>(
      AsappServiceUrls.applications,
      request
    );
  }

  getStatusesCount(
    request: ApplicationStatusesRequestDto
  ): Observable<ApplicationStatusCountDto[]> {
    return this.api.get<ApplicationStatusCountDto[]>(
      AsappServiceUrls.statuses,
      request
    );
  }
  getSiteDetails(): Observable<SiteDetailDto> {
    return this.api.get<SiteDetailDto>(AsappServiceUrls.siteDetails);
  }

  getUserBranch(): Observable<UserBranchDto[]> {
    return this.api.get<UserBranchDto[]>(AsappServiceUrls.userBranch);
  }
}
