import { PublicPrivateLinkDto, UserDto } from '@core/services/dto';

export class PublicPrivateLinkModel {
  createdBy: UserDto;
  createdDate: Date;
  description: string;
  id: number;
  name: string;
  position: number;
  type: string;
  url: string;

  constructor(dto: PublicPrivateLinkDto) {
    for (const prop in dto) {
      if (dto.hasOwnProperty(prop)) {
        this[prop] = dto[prop];
      }
    }
  }
}
