import { ConversationType } from '@shared/configs/conversation-types.config';

export enum WorkflowTypeEnum {
  All = 1,
  Conversations = 2,
  Opportunities = 3,
  Concerns = 4
}

export const WorkflowTypeStringEnum = {
  All: 'All',
  Conversations: 'Conversations',
  Opportunities: 'Opportunities',
  Concerns: 'Concerns'
};

export function getWorkflowType(conversationType): string {
  let type: string;

  switch (conversationType) {
    case ConversationType.conversation:
      type = WorkflowTypeStringEnum.Conversations;
      break;
    case ConversationType.opportunity:
      type = WorkflowTypeStringEnum.Opportunities;
      break;
    case ConversationType.concern:
      type = WorkflowTypeStringEnum.Concerns;
      break;
  }

  return type;
}
