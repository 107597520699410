
<div class="mat-dialog-custom-container">
  <ng-container *ngIf="isMinimizeAndMoveAble; else notMovable">
    <div fxLayout class="header moveable"
        cdkDrag
        cdkDragRootElement=".cdk-overlay-pane"
        cdkDragHandle
        cdkDragBoundary=".cdk-overlay-container"
        >

      <div class="title" fxFlex>{{data.dialogTitle}} </div>
      <div class="sub-title">{{data.dialogSubTitle}}
      </div>
      <div fxFlex="16px" class="minimize" (click)="onMinimize()">
        <i class="icon-minus"></i>
      </div>
      <div fxFlex="16px" class="close" (click)="closeDialog()">
        <i class="icon-cross"></i>
      </div>
    </div>
  </ng-container>


  <ng-template #notMovable>
    <div fxLayout class="header">
      <div class="title" fxFlex>{{data.dialogTitle}} </div>
      <div class="sub-title">{{data.dialogSubTitle}}
      </div>
      <div fxFlex="16px" class="close" (click)="closeDialog()">
        <i class="icon-cross"></i>
      </div>
    </div>
  </ng-template>



  <div *ngIf="data.dialogType === 'create_conversation'">
    <app-create-conversation-wrapper (conversationTypeChange)="onConversationTypeChange($event)" (closeDialog)="closeDialog()" (formChanged)="onFormChangedStatus($event)"></app-create-conversation-wrapper>
  </div>
  <div *ngIf="data.dialogType === 'task'">
    <app-create-task [preselectedMembers]="[preselectedMember]" [preselectedAccounts]="preselectedAccounts"
      (closeDialog)="closeDialog()" (formChanged)="onFormChangedStatus($event)"></app-create-task>
  </div>
  <div *ngIf="data.dialogType === 'create_note'">
    <app-create-note (closeDialog)="closeDialog()" (formChanged)="onFormChangedStatus($event)"></app-create-note>
  </div>
  <div *ngIf="data.dialogType === 'sale'">
    <app-create-sales (closeDialog)="closeDialog()" (formChanged)="onFormChangedStatus($event)"></app-create-sales>
  </div>
  <div *ngIf="data.dialogType === 'taskTemplate'">
    <app-task-template-modal-dialog (closeDialog)="closeDialog()" [Id]="data.id"></app-task-template-modal-dialog>
  </div>
  <div *ngIf="data.dialogType === 'create_prospect'">
    <app-create-prospect (closeDialog)="closeDialog()" [Id]="data.id" (formChanged)="onFormChangedStatus($event)"></app-create-prospect>
  </div>
  <div *ngIf="data.dialogType === 'offerTemplate'">
    <app-offer-template-model-dialog (closeDialog)="closeDialog()" [Id]="data.id"></app-offer-template-model-dialog>
  </div>
</div>