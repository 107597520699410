<div
  *ngIf="isSummaryVisible"
  class="container wrapper"
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  <div class="product-details" fxLayoutGap="17px" fxLayout="row">
    <div>
      <i class="icon-product"></i>
    </div>
    <div>
      <div class="type">
        {{ productTypeLabel }}
      </div>
      <div class="name">
        {{ productLabel }}
      </div>
      <div class="sale">
        <span class="sale-type">{{ form.controls.saleType.value }} </span>-
        {{ form.controls.fundSource.value }}
        {{
          form.controls.value.value ? " - $" + form.controls.value.value : ""
        }}
        {{ getDate() ? " - Renewal Date: " + getDate() : "" }}
      </div>
    </div>
  </div>
  <div class="product-btns" fxLayout="row" fxLayoutAlign="space-between center">
    <div>
      <i
        *ngIf="createMode"
        class="icon icon-trash"
        (click)="deleteTemporary()"
      ></i>
      <i *ngIf="editMode" class="icon icon-trash" (click)="delete()"></i>
      <i
        *ngIf="editMode || createMode"
        class="icon icon-edit"
        (click)="edit()"
      ></i>
      <i *ngIf="viewMode" class="icon icon-view" (click)="view()"></i>
    </div>
  </div>
</div>

<div class="wrapper" *ngIf="isEdit">
  <form [formGroup]="form">
    <div fxLayoutGap="17px" fxFill>
      <div fxFlex="50" class="left-col">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>Product Type</mat-label>
          <mat-select formControlName="productType">
            <ng-container *ngFor="let option of productTypeOptions">
              <mat-option
                [value]="option.value"
                (click)="saveProductTypeLabel(option.label)"
                >{{ option.label }}</mat-option
              >
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="50">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>Product</mat-label>

          <mat-select formControlName="productId">
            <ng-container *ngFor="let option of productOptions">
              <mat-option
                [value]="option.value"
                (click)="saveProductLabel(option.label)"
                >{{ option.label }}</mat-option
              >
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div fxLayoutGap="17px" fxFill>
      <div fxFlex="30">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>Sale Type</mat-label>
          <mat-select formControlName="saleType">
            <ng-container *ngFor="let option of saleTypesOpt">
              <mat-option [value]="option.value">{{ option.label }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="30">
        <mat-form-field fxFlex="100" floatLabel="always" appearance="outline">
          <mat-label>Value</mat-label>
          <input
            type="number"
            matInput
            placeholder="Value"
            formControlName="value"
          />
          <span class="prefix" matPrefix>$&nbsp;</span>
        </mat-form-field>
      </div>
      <div fxFlex="33">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>Fund Source</mat-label>
          <mat-select formControlName="fundSource">
            <ng-container *ngFor="let option of fundSourceOpt">
              <mat-option [value]="option.value">{{ option.label }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div fxLayoutGap="17px" fxFill>
      <div fxFlex="70">
        <app-input-autocomplete
          formControlName="soldBy"
          placeholder="Sold By"
          [lengthToTriggerSearch]="GLOBAL_SETTINGS.minLengthToTriggerSearch"
          [options]="soldByUsers$ | async"
          (selectedItem)="chooseSoldByUser($event)"
          [defaultValue]="defaultUserName"
          icon="search"
        >
        </app-input-autocomplete>
      </div>
      <mat-form-field appearance="outline" fxFlex="30">
        <mat-label>Renewal Date</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          formControlName="renewalDate"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>      
    </div>
    <div fxLayoutGap="17px" fxFill>
      <mat-form-field fxFlex="50" floatLabel="always" appearance="outline">
        <mat-label>Actual Value</mat-label>
        <input
          type="number"
          matInput
          placeholder="Actual Value"
          formControlName="actualvalue"
        />
        <span class="prefix" matPrefix>$&nbsp;</span>
      </mat-form-field>

      <mat-form-field appearance="outline" fxFlex="50">
        <mat-label>Maturity Date</mat-label>
        <input
          matInput
          [matDatepicker]="mpicker"
          formControlName="maturityDate"
        />
        <mat-datepicker-toggle matSuffix [for]="mpicker"></mat-datepicker-toggle>
        <mat-datepicker #mpicker></mat-datepicker>
      </mat-form-field>
    </div>
  </form>
  <div>
    <div
      fxLayout="row"
      fxLayoutGap="15px"
      fxLayoutAlign="end"
      fxFlex="100"
      *ngIf="!isSummaryVisible && createMode"
    >
      <cm-button color="secondary" fxFlex="125px" (click)="cancel()"
        >Cancel</cm-button
      >
      <cm-button
        color="primary"
        fxFlex="105px"
        (click)="form.valid && save()"
        [disabled]="!this.form.valid"
        >Add
      </cm-button>
    </div>
    <div
      fxLayout="row"
      fxLayoutGap="15px"
      fxLayoutAlign="end"
      *ngIf="isSummaryVisible || editMode"
    >
      <cm-button
        color="primary"
        (click)="form.valid && save()"
        [disabled]="!this.form.valid"
        >Update
      </cm-button>
    </div>
  </div>
</div>

<div class="wrapper" *ngIf="isView">
  <form [formGroup]="form">
    <div fxLayoutGap="17px" fxFill>
      <div fxFlex="50">
        <mat-form-field fxFlex="100" appearance="outline">
          <mat-label>Product Type</mat-label>
          <input formControlName="productTypeName" matInput readonly />
        </mat-form-field>
      </div>
      <div fxFlex="50">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>Product</mat-label>
          <input formControlName="productName" matInput readonly />
        </mat-form-field>
      </div>
    </div>
    <div fxLayoutGap="17px" fxFill>
      <div fxFlex="30">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>Sale Type</mat-label>
          <input formControlName="saleType" matInput readonly />
        </mat-form-field>
      </div>
      <div fxFlex="30">
        <mat-form-field fxFlex="100" floatLabel="always" appearance="outline">
          <mat-label>Value</mat-label>
          <input
            type="number"
            matInput
            placeholder="Value"
            formControlName="value"
            readonly
          />
          <span class="prefix" matPrefix>$&nbsp;</span>
        </mat-form-field>
      </div>
      <div fxFlex="33">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>Fund Source</mat-label>
          <input
            matInput
            placeholder="Fund Source"
            formControlName="fundSource"
            readonly
          />
        </mat-form-field>
      </div>
    </div>
    <div fxLayoutGap="17px" fxFill>
      <div fxFlex="70">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>Sold By</mat-label>
          <input
            matInput
            placeholder="Sold By"
            formControlName="soldByUser"
            readonly
          />
        </mat-form-field>
      </div>
      <mat-form-field appearance="outline" fxFlex="30">
        <mat-label>Renewal Date</mat-label>
        <input matInput formControlName="renewalDateFormatted" readonly />
      </mat-form-field>
    </div>
    <div fxLayoutGap="17px" fxFill>
      <mat-form-field fxFlex="50" floatLabel="always" appearance="outline">
        <mat-label>Actual Value</mat-label>
        <input
          type="number"
          matInput
          placeholder="Actual Value"
          formControlName="actualvalue"
          readonly
        />
        <span class="prefix" matPrefix>$&nbsp;</span>
      </mat-form-field>

      <mat-form-field appearance="outline" fxFlex="50">
        <mat-label>Maturity Date</mat-label>
        <input
          matInput
          [matDatepicker]="mpicker"
          formControlName="maturityDate" readonly
        />
        <mat-datepicker-toggle matSuffix [for]="mpicker"></mat-datepicker-toggle>
        <mat-datepicker #mpicker></mat-datepicker>
      </mat-form-field>
    </div>
  </form>
  <div>
    <div
      fxLayout="row"
      fxLayoutGap="15px"
      fxLayoutAlign="end"
      fxFlex="100"
      *ngIf="!isSummaryVisible"
    >
      <cm-button color="secondary" fxFlex="125px" (click)="cancel()"
        >Cancel</cm-button
      >
    </div>
  </div>
</div>
