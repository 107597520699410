import { AccountDto } from '@core/services/dto';

export class SearchAccountOptionsModel {
  label?: string;
  ref: string;
  type: string;
  value?: string;
  accountNumber?: string;
  accountName?: string;

  constructor(dto: AccountDto) {
    this.label = `${dto.memberName} - ${dto.name ? dto.name : 'N/A'} - ${dto.accountNumber}`;
    this.value = dto.name ? dto.name : 'N/A';
    this.ref = dto.accountId.toString();
    this.type = 'account';
    this.accountNumber = dto.accountNumber;
    this.accountName = dto.name ? dto.name : 'N/A';
  }
}
