import { NgModule } from '@angular/core';
import { CustomTableComponent } from '@shared/components/custom-table/custom-table.component';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CommonModule } from '@angular/common';
import { DataPropertyGetterPipe } from '@shared/components/custom-table/pipes/data-property-getter.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MemberListComponent } from './components/member-list/member-list.component';
import { RouterModule } from '@angular/router';
import { TableFilterComponent } from './components/table-filter/table-filter.component';
import { TableFilterRowDefDirective } from '@shared/components/custom-table/directives/table-filter-row-def.directive';
import { CmButtonModule } from '@shared/components/cm-button';
import { ThreeDotsMenuModule } from '@shared/components/three-dots-menu/three-dots-menu.module';
import { DynamicPipe } from '@shared/components/custom-table/pipes/dynamic-pipe.pipe';
import { AccordionRowItemComponent } from '@shared/components/custom-table/components/row-item/accordion-row-item.component';
import { RecordColumnComponent } from '@shared/components/custom-table/components/record-column/record-column.component';
import { AccordionBuilderComponent } from '@shared/components/custom-table/components/accordion-builder.component.ts/accordion-builder.component';
import { RegionBuilderComponent } from './components/region-builder/region-builder.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [
    CustomTableComponent,
    DataPropertyGetterPipe,
    DynamicPipe,
    MemberListComponent,
    TableFilterComponent,
    TableFilterRowDefDirective,
    AccordionBuilderComponent,
    AccordionRowItemComponent,
    RecordColumnComponent,
    RegionBuilderComponent
  ],
  imports: [
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    CommonModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    RouterModule,
    // NgxTextOverflowClampModule,
    CmButtonModule,
    MatCheckboxModule,
    ThreeDotsMenuModule,
    FormsModule,
    MatTooltipModule,
    MatMenuModule
  ],
  exports: [
    CustomTableComponent,
    TableFilterRowDefDirective,
    MemberListComponent
  ],
})
export class CustomTableModule { }
