import { SaleDto } from '@core/services/dto/reports/sales.dto';

export class SearchSalesOptionsModel {
  label?: string;
  ref: string;
  type: string;
  value?: string;
  accountNumber?: string;
  accountName?: string;
  active?: any;
  id: number;

  constructor(dto: SaleDto) {
    this.id = dto.id || 1;
    this.label = dto.name ? dto.name : '';
    this.active = dto.active || true;
    this.type = 'sales';
    this.value =  dto.name ? dto.name : '';
  }
}


