import { ChosenLinkModel } from '@shared/models';
import { SalesProductDto } from '@core/services/dto';
import { SearchMemberProspectOptionsModel } from './search.member.prospect.options.model';

export interface SaleCreateInterface {
  applicationId: string;
  campaignId: number;
  branchId: number;
  products: SalesProductDto[];
  referrers: ChosenLinkModel[];
  links?: SearchMemberProspectOptionsModel[];
}
