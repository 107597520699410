<div class="confirmation-modal">
  <div mat-dialog-title class="title">
    {{title}}
  </div>

  <div mat-dialog-content class="message">
    <p *ngIf="!isHtmlContent">{{message}}</p>
    <div *ngIf="isHtmlContent" [innerHTML]="message"></div>
  </div>

  <div  fxLayout fxLayoutAlign="flex-end center" [fxLayoutGap]="buttonsGap" class="buttons-list">
    <a *ngIf="showCancelBtn && !borderCancelBtn" class="cancel-link" (click)="onDismiss()">{{ cancelButtonText }}</a>

    <cm-button color="secondary" (click)="onDismiss()" *ngIf="showCancelBtn && borderCancelBtn">{{cancelButtonText}}</cm-button>

    <cm-button (click)="onConfirm()">{{submitButton}}</cm-button>
  </div>
</div>

