export enum TaskTemplatePriorityEnum {
  Low,
  Medium,
  High
}

export const TaskTemplatePriorityStringEnum = {
  Low: 'Low',
  Medium: 'Medium',
  High: 'High'
};
