import { EcmFilesDto } from '@core/services/dto';
import { DEFAULT_DATE_FORMAT, formatDateToLocalSimple } from '@shared/utils';

export class EcmFilesModel {
  id: number;
  name: string;
  lastUpdated: string;
  isFile: boolean;
  fileMetadataId: number;
  isDisabled: boolean;
  subType: string;
  type: string;

    constructor(dto: EcmFilesDto) {
      for (const prop in dto) {
        if (dto.hasOwnProperty(prop)) {
          this[prop] = dto[prop];
        }
      }
      this.isFile = true;
      this.lastUpdated = dto.lastUpdated ? formatDateToLocalSimple(dto.lastUpdated, DEFAULT_DATE_FORMAT) : dto.lastUpdated;
      this.isDisabled = false;
      this.type = dto.type?.name;
      this.subType = dto.subType?.name;
    }
}
