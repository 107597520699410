import { OfferTemplateIconsDto } from '@core/services/dto';

export class OfferTemplateIcons {
    id: number;
    iconPath: string;

    constructor(dto: OfferTemplateIconsDto) {
        this.id = dto.id;
        this.iconPath = dto.iconPath;
    }
}
