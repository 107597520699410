import { CanadaPostAddressRetrieveDto } from '@core/services/dto/canada-post-address-retrieve.dto';

export class CanadaPostAddressSearchModel {
  value: string;
  label: string;
  next: string;

  constructor(addr: CanadaPostAddressRetrieveDto) {
    this.value = addr.id;
    this.label = `${addr.line1} ${addr.city}, ${addr.provinceCode}, ${addr.postalCode}`;
    this.next = 'Retrieve';
  }
}
