import { Injectable } from '@angular/core';
import { ApiClient } from '@core/services/api-client.http.service';
import { Observable } from 'rxjs';
import { EmailTemplateDto, SearchEmailTemplatesDto } from '@core/services/dto';
import { EmailUrls, SMSUrls } from '@core/constants';
import { SendEmailRequestDto } from '@shared/models/requests/send.email.request.dto';
import { AttachmentModel } from '@shared/models';
import { EmailTemplateSearchRequestDto } from '@shared/models/requests/email-template-search.request.dto';
import { SMSTemplateDto } from '../dto/sms-template.dto';
import { SMSTemplateSearchRequestDto } from '@shared/models/requests/sms-template-search.request.dto';
import { SearchSMSTemplatesDto } from '../dto/search-sms-template.dto';
import { SendSMSTemplateDto } from '../dto/SendSms.Dto';
import { SendSmsFromTask } from '@shared/models/SendSmsFromTask.model';

@Injectable({
  providedIn: 'root'
})

export class SMSTemplatesApiService {

  constructor(private api: ApiClient) {
  }
  searchTemplates(request?: SMSTemplateSearchRequestDto): Observable<SearchSMSTemplatesDto> {
    return this.api.get<SearchSMSTemplatesDto>(SMSUrls.templates, request);
  }


getTemplate(id: string): Observable<SMSTemplateDto> {
    return this.api.get<SMSTemplateDto>(SMSUrls.templateById(id));
  }

  updateTemplate(id: string, request: SMSTemplateDto): Observable<SMSTemplateDto> {
    return this.api.put<SMSTemplateDto>(SMSUrls.templateById(id), request);
  }
  createTemplate(request: SMSTemplateDto): Observable<SMSTemplateDto> {
    return this.api.post<SMSTemplateDto>(SMSUrls.smstemplates, request);
  }
  SendSms(request: SendSmsFromTask): Observable<SendSMSTemplateDto> {
    return this.api.post<SendSMSTemplateDto>(SMSUrls.SendSms, request);
  }
//   getLayout(): Observable<AttachmentModel> {
//     return this.api.get<AttachmentModel>(EmailUrls.defaultLayout);
//   }
}
