import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MemberUrls } from '@core/constants/endpoints.constants';
import { ApiClient } from '@core/services/api-client.http.service';
import { AccountDto, PaginatedResponseDto } from '@core/services/dto';

@Injectable({
  providedIn: 'root'
})
export class AccountsApiService {

  constructor(private api: ApiClient) {
  }

  getAccounts(request: any): Observable<PaginatedResponseDto<AccountDto>> {
    return this.api.get<PaginatedResponseDto<AccountDto>>(MemberUrls.accounts, request);
  }

  getAccountsCount(memberId: number): Observable<number> {
    return this.api.get<number>(MemberUrls.accountsCount(memberId));
  }
}
