import { OfferTypeEnum } from '@shared/enums/offer-type.enum';
import { SelectOption } from '@shared/interfaces';

export const TypeOptions: SelectOption<string>[] = [
    {
        value: OfferTypeEnum[OfferTypeEnum.Offer],
        label: 'Offer'
    },
    {
        value: OfferTypeEnum[OfferTypeEnum.Notice],
        label: 'Notice'
    }
];

export const DueDateType: SelectOption<string>[] = [
  {
    value: 'Days',
    label: 'Day(s)',
  },
  {
    value: 'Weeks',
    label: 'Week(s)',
  },
  {
    value: 'Months',
    label: 'Month(s)',
  },
];
