export enum TaskPriorityEnum {
  Low = 1,
  Medium = 2,
  High = 3
}

export const TaskPriorityStringEnum = {
  Low: 'Low',
  Medium: 'Medium',
  High: 'High'
};
