<div class="root sm-font-container">
  <form [formGroup]="form" fxLayout="row wrap" fxLayoutGap="15px 0 grid">
    <mat-form-field appearance="outline" fxFlex="1 1 60">
      <mat-label>Offer Template Name</mat-label>
      <input
        matInput
        placeholder="Offer Template Name"
        formControlName="templateName"
      />
    </mat-form-field>
    <mat-form-field fxFlex="1 1 40" appearance="outline">
      <mat-label>Status</mat-label>
      <mat-select formControlName="status">
        <mat-option *ngFor="let item of statusOptions" [value]="item.value">
          {{ item.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" fxFlex="1 1 60">
      <mat-label>Offer Title</mat-label>
      <input matInput placeholder="Offer Title" formControlName="title" />
    </mat-form-field>
    <mat-form-field fxFlex="1 1 40" appearance="outline">
      <mat-label>Offer Type</mat-label>
      <mat-select formControlName="offerType">
        <mat-option *ngFor="let item of typeOptions" [value]="item.value">
          {{ item.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" fxFlex="1 1 100">
      <mat-label>Summary</mat-label>
      <textarea
        formControlName="summary"
        matInput
        placeholder="Offer Summary"
      ></textarea>
    </mat-form-field>

    <div fxFlex="100" *ngIf="isTypeOffer()">
      <div class="editor-title">Description</div>
      <div class="editor">
        <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
        </ngx-editor-menu>
        <ngx-editor [editor]="editor" formControlName="description">
        </ngx-editor>
      </div>
    </div>

    <mat-radio-group
      *ngIf="isTypeOffer()"
      appearance="outline"
      aria-label="Send from"
      fxFlex="1 1 100"
      formControlName="offerLinkType"
    >
      <mat-label style="margin-right: 15px">Offer Link Type</mat-label>
      <mat-radio-button style="margin-right: 15px" value="Product"
        >Product</mat-radio-button
      >
      <mat-radio-button value="URL">URL</mat-radio-button>
    </mat-radio-group>
    <br /><br />
    <mat-form-field *ngIf="isTypeOffer() && !isOfferLinkTypeProduct()" appearance="outline" fxFlex="1 1 100">
      <mat-label>Redirect URL</mat-label>
      <input
        matInput
        placeholder="Offer Title"
        formControlName="redirectLink"
      />
    </mat-form-field>

    <mat-form-field
      *ngIf="isTypeOffer() && isOfferLinkTypeProduct()"
      appearance="outline"
      fxFlex="1 1 100"
    >
      <mat-label>Product SKU</mat-label>
      <input matInput placeholder="Product SKU" formControlName="productSku" />
    </mat-form-field>

    <div fxLayoutAlign="start end">
      <div fxFlex="1 1 60">
        <div class="due-in" fxLayout="row wrap">
          <span fxFlex="100">Offer Duration</span>
          <div fxLayout="row wrap" fxLayoutGap="15px 0 grid">
            <mat-form-field appearance="outline" fxFlex="1 1 35">
              <input
                matInput
                (change)="updateDueIn()"
                formControlName="dueIn"
                placeholder="Duration In"
                type="number"
              />
            </mat-form-field>
            <mat-form-field fxFlex="1 2 35" appearance="outline">
              <mat-select formControlName="dueDateType">
                <mat-option
                  *ngFor="let item of dueDateType"
                  [value]="item.value"
                >
                  {{ item.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <mat-form-field *ngIf="isTypeOffer()" appearance="outline" fxFlex="1 1 100">
      <mat-label>Call To Action</mat-label>
      <input
        matInput
        placeholder="Offer Call To Action"
        formControlName="offerCallToAction"
      />
    </mat-form-field>

    <div fxFlex="100" *ngIf="isTypeOffer()">
      <div class="editor-title">Terms & Conditions</div>
      <div class="editor">
        <ngx-editor-menu [editor]="editortc" [toolbar]="toolbartc">
        </ngx-editor-menu>
        <ngx-editor [editor]="editortc" formControlName="termAndCondition">
        </ngx-editor>
      </div>
    </div>

    <mat-radio-group
      style="margin-top: 20px"
      fxFlex="1 1 100"
      aria-label="Send from"
      formControlName="icon"
    >
      <mat-label>Offer Icon</mat-label>
      <br />
      <ng-container *ngFor="let item of offerTemplateIcons">
        <mat-radio-button style="padding: 5px" value="{{ item.id }}"
          ><img height="50" width="50" src="{{ item.iconPath }}"
        /></mat-radio-button>
      </ng-container>
    </mat-radio-group>

    <mat-form-field *ngIf="isTypeOffer()" appearance="outline" fxFlex="1 1 50">
      <mat-label>Offer Amount</mat-label>
      <input
        matInput
        placeholder="Offer Amount"
        type="number"
        formControlName="offerAmount"
      />
    </mat-form-field>

    <mat-form-field *ngIf="isTypeOffer()" appearance="outline" fxFlex="1 1 50">
      <mat-label>Offer Rate</mat-label>
      <input
        matInput
        placeholder="Offer Rate"
        type="number"
        formControlName="offerRate"
      />
    </mat-form-field>

    <mat-divider></mat-divider>

    <mat-form-field
      *ngIf="isTypeOffer() && isEditMode"
      appearance="outline"
      fxFlex="1 1 100" style="margin-top: 15px;"
    >
      <mat-label>Banner Image</mat-label>
      <input
        matInput
        placeholder="Banner Image"
        type="text"
        formControlName="bannerImage"
        readonly
      />
    </mat-form-field>

    <div *ngIf="isTypeOffer() && !isEditMode">
      <app-upload-file
        [allowedExtensions]="['.png', '.jpg']"
        [max]="1"
        [title]="'Banner Image'"
        [fileType]="FileTypeEnum.Offer"
        [parentForm]="form"
      >
      </app-upload-file>
    </div>
    <div *ngIf="isTypeOffer() && isEditMode">
      <app-upload-file
        [allowedExtensions]="['.png', '.jpg']"
        [max]="1"
        [title]="'Banner Image Upload'"
        [fileType]="FileTypeEnum.Offer"
        [parentForm]="form"
        [readOnly]="false"
      >
      </app-upload-file>
    </div>
  </form>
  <div *ngIf="Id" fxLayout="row" fxLayoutAlign="end" class="mt">
    <span class="cancel" (click)="this.closeDialog.emit()">Cancel</span>
    <cm-button
      color="primary"
      (click)="form.valid && record()"
      [disabled]="!form.valid"
    >
      Save
    </cm-button>
  </div>
  <div *ngIf="!Id">
    <cm-button
      color="primary"
      class="record-btn"
      (click)="form.valid && record()"
      [disabled]="!form.valid"
    >
      Save Offer Template
    </cm-button>
  </div>
</div>
