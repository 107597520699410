import { AnnouncementsDto, BannerDto, BranchDto, UserDto } from '@core/services/dto';
import { AttachmentModel } from '@shared/models/attachment.model';

export class AnnouncementsModel {
  id: number;
  title: string;
  description: string;
  type: string;
  status: string;
  headerBanner: BannerDto;
  footerBanner: BannerDto;
  createdDate: Date;
  createdBy: UserDto;
  branches: BranchDto[];
  attachments: AttachmentModel[];
  created: string;
  banners: BannerDto[] = [];
  image: string;

  constructor(dto: AnnouncementsDto) {
    for (const prop in dto) {
      if (dto.hasOwnProperty(prop)) {
        this[prop] = dto[prop];
      }
    }
    this.createdDate = new Date(dto.createdDate);
    if (this.headerBanner) {
      this.banners.push(this.headerBanner);
      this.headerBanner.position = 'header';
      this.image = this.headerBanner.link;
    }
    if (this.footerBanner) {
      this.banners.push(this.footerBanner);
      this.footerBanner.position = 'footer';
    }
  }
}
