import { FileTypeEnum } from '@shared/enums';
import { take } from 'rxjs/operators';
import { GlobalStateService } from '@core/services/global.state.service';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FileApiService } from '@core/services/api.services/file-api.service';
import { autoUnsubscribeMixin } from '@core/helpers/auto-unsubscribe.mixin';
import { FileValidator } from '@shared/validators';
import { AttachmentModel } from '@shared/models';
import { BANNER_POSITION_OPTIONS } from '@shared/constants/select-options.constants';
import { BannerPositionEnum } from '@shared/constants/select-options.enums';
import { saveAsBlob } from '@shared/utils';
import { EcmUrls } from '@core/constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent extends autoUnsubscribeMixin() implements OnInit, OnChanges {

  @Input() readOnly: boolean;
  @Input() files: AttachmentModel[] = [];
  @Input() statusDetails: string;
  @Input() fileType: string;
  @Input() isBannerDdl = false;
  @Input() brandingType = 'Default';
  @Input() title = 'Attachment';
  @Input() parentForm: FormGroup;
  @Input() maxFileSize = 10485760;
  @Input() max = 100;
  @Input() saveOnFly = true;
  @Input() allowedExtensions = ['.png', '.pdf', '.doc', '.docx', '.jpeg', '.wav', '.xls', '.xlsx', '.msg'];

  @Output() xlsUploaded: EventEmitter<boolean> = new EventEmitter<boolean>(false);


  attachments = new FormArray([]);
  headerBannerId = new FormControl();
  footerBannerId = new FormControl();
  fileList = new FormArray([]);
  form: FormGroup;
  filesToUpload: File[];
  extensions = [];
  isError = false;
  file: File;
  errorArr = [];
  bannerOptions = BANNER_POSITION_OPTIONS;
  bannerPositionEnum = BannerPositionEnum;
  bannerPosition = BannerPositionEnum[BannerPositionEnum.header];

  constructor(private service: FileApiService, private fb: FormBuilder, private globalStateService: GlobalStateService,
              private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.initialize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initialize();
    if (changes.saveOnFly && changes.saveOnFly.currentValue && !!this.file) {
      this.saveFile(this.file, changes.saveOnFly.currentValue);
    }
  }

  initialize(): void {
    this.extensions = this.allowedExtensions.map(ext => {
      return ext.replace('.', '');
    });
    this.clear();
    this.form = this.fb.group({
      fileList: this.fileList
    });
    if (!this.readOnly) {
      this.parentForm.removeControl('attachments');  // this is necessary when adding several entities in a row (like interaction)
      this.parentForm.removeControl('fileList');

      this.parentForm.removeControl('headerBannerId');
      this.parentForm.removeControl('footerBannerId');

      this.parentForm.addControl('attachments', this.attachments);
      this.parentForm.addControl('fileList', this.form);

      this.parentForm.addControl('headerBannerId', this.headerBannerId);
      this.parentForm.addControl('footerBannerId', this.footerBannerId);
    }
    if (this.files?.length) {
      this.files.forEach(file => {
        this.createFormGroup(file);
        this.addFileToAttachment(file);
      });

      this.initializeBanners();
    }
    this.maxFileSize = this.fileType === 'Ecm' ? 157286400 : this.maxFileSize;
  }

  addPosition(file, value, i): void {
    this.headerBannerId.setValue(null);
    this.footerBannerId.setValue(null);
    // TODO: need to refactor this in the future.
    //  This logic stands for nullifying identical values
    const formArray = this.form.get('fileList') as FormArray;
    if (formArray.length > 1) {
      // @ts-ignore
      const formGroup = formArray.at(1 - i).controls.position;
      if (value === formGroup.value) {
        formGroup.setValue(null);
      }
    }

    this.initializeBanners();
  }

  initializeBanners(): void {
    (this.form.controls.fileList as FormArray).controls.forEach((element, index) => {
      // @ts-ignore
      const control = element.controls;
      if (control.position.value) {
        if (control.position.value === BannerPositionEnum[BannerPositionEnum.header]) {
          this.headerBannerId.setValue(control.id.value);
        } else if (control.position.value === BannerPositionEnum[BannerPositionEnum.footer]) {
          this.footerBannerId.setValue(control.id.value);
        }
      }
    });
  }

  public removeFileFromList(i: number, file: FormGroup | AbstractControl): void {
    this.fileList.removeAt(i);

    if (file.value.id) {
      if (file.value.position === 'header') {
        this.headerBannerId.setValue(null);
        this.initializeBanners();
      }
      else if (file.value.position === 'footer') {
        this.footerBannerId.setValue(null);
        this.initializeBanners();
      }
      this.removeFileFromAttachment(file.value.id);
    }
  }

  public fileBrowse(files: File): void {
    this.file = files;
    this.saveFile(this.file, this.saveOnFly);
  }

  public saveFile(files: File, readyForSave): void {
    this.filesToUpload = Object.values(files);
    const currentUrl = this.router.url;
    let templateId: number | null = null;
    let importType: string | null = null;
    if (currentUrl.includes('import-conversation')) {
      templateId = 1;
      importType = 'Manual';
    }
    else if (currentUrl.includes('import-opportunities')) {
      templateId = 2;
      importType = 'Manual';
    }
    else if (currentUrl.includes('import-concern')) {
      templateId = 3;
      importType = 'Manual';
    }
    this.maxFileSize = this.fileType === 'Ecm' ? 157286400 : this.maxFileSize;
    this.filesToUpload.forEach(file => {
      const fileExtension = file.name.toLowerCase().split('.').pop();
      const formData: any = new FormData();
      formData.append('file', file);
      formData.append('type', this.brandingType);

      if (file.size < this.maxFileSize && this.extensions.includes(fileExtension) && readyForSave) {
        this.service.setAttachment(formData, this.fileType, templateId,importType).subscribe((data: any) => {
          this.isError = false;
          if (data?.id) {
            this.createFormGroup(data);
            this.addFileToAttachment(data);
          }
          if (
            this.fileType === 'Xls' ||
            this.fileType === FileTypeEnum.OfferXls
          ) {
            this.xlsUploaded.emit(true);
          }
        }, (err: any) => {
          this.isError = false;
          if (err && err.errors) {
            this.errorArr = [];
            for (const [key, value] of Object.entries(err.errors)) {
              // eslint-disable-next-line
              this.errorArr.push(`${key}: ${value}`);
            }
            this.isError = true;
          }
        });
      } else {
        this.createFormGroup(file);
      }
    });
  }

  public createFormGroup(file): void {
    if (file) {
      (this.form.controls.fileList as FormArray).push(this.fb.group({
        contentType: [file.type],
        link: [file.link],
        id: [file.id ? file.id : null],
        name: [file.name, FileValidator.fileExtension(this.extensions)],
        url: [file.url ? file.url : null],
        size: [file.size ? file.size : null, FileValidator.fileMaxSize(this.maxFileSize)],
        position: [file.position]
      }));
    }
  }

  public getFile(url: string, name: string, id: number): void {
    if (this.fileType === 'Ecm') {
      this.service.downloadFile(EcmUrls.loadFile, { includedFileMetadataIds: id }).subscribe(
        (data: Blob) => saveAsBlob(data, name));
    } else if (url) {
      this.service.downloadFile(url).subscribe(
        (data: Blob) => saveAsBlob(data, name));
    }
  }

  public clear(): void {
    this.attachments.clear();
    this.fileList.clear();
  }

  public addFileToAttachment(file: AttachmentModel): void {
    this.attachments.push(new FormControl(file));
  }

  private removeFileFromAttachment(id: number): void {
    const index = this.attachments.value.findIndex((x: { id: number; }) => x.id === id);
    if (index > -1) {
      this.attachments.removeAt(index);
    }
    if (this.fileType === 'Html') {
      this.service.removeDefaultEmailTemplateLayout().subscribe(response => {
        this.files = response;
      });
    }
  }
}
