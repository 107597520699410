import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NoteUrls } from '@core/constants';
import { PaginatedResponseDto } from '@core/services/dto';
import { NoteDto } from '@core/services/dto/note.dto';
import { NoteApiRequest } from '@shared/models/requests/note-request.dto';
import { ApiClient } from '@core/services/api-client.http.service';
import { NoteModel } from '@shared/models';

@Injectable({
  providedIn: 'root'
})
export class NoteApiService {
  constructor(private api: ApiClient) {}

  getNotes(request: NoteApiRequest): Observable<PaginatedResponseDto<NoteDto>> {
    return this.api.get<PaginatedResponseDto<NoteDto>>(NoteUrls.notes, request);
  }

  setNote(note: NoteModel): Observable<NoteModel> {
    return this.api.post<NoteModel>(NoteUrls.notes, note);
  }

  getNote(id: number): Observable<NoteDto> {
    return this.api.get<NoteDto>(NoteUrls.note(id));
  }

  deleteNote(id: number): Observable<any> {
    return this.api.delete<any>(NoteUrls.note(id));
  }

  updateNote(id: number, request: NoteModel): Observable<NoteModel> {
    return this.api.put<any>(NoteUrls.note(id), request);
  }

}
