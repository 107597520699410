import {
  Component,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  ElementRef,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EmailTemplateDto, TaskTemplateDto } from '@core/services/dto';
import { GlobalStateService } from '@core/services/global.state.service';
import { CreateConversationStateService } from '@shared/components/conversation/create-conversation.state.service';
import { UploadFileComponent } from '@shared/components/upload-file/upload-file.component';
import { GLOBAL_SETTINGS } from '@shared/constants/global-settings';
import { FileTypeEnum, TaskPriorityEnum, TaskTypeEnum } from '@shared/enums';
import { SelectOption } from '@shared/interfaces';
import {
  SearchMemberOptionsModel,
  SearchUserOptionsModel,
  TaskCreateInterface,
  UserNameModel,
} from '@shared/models';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  EmptyTaskTemplateDropdownOption,
  NoneTaskTemplateDropdownOption,
} from '@shared/components/create-task/create-task.config';
import {
  TASK_PRIORITY_OPTIONS,
  TASK_TYPE_OPTIONS,
} from '@shared/constants/select-options.constants';
import { autoUnsubscribeMixin } from '@core/helpers/auto-unsubscribe.mixin';
import { AssigneeFormControl } from '@shared/controls/assignee-form-control';
import * as moment from 'moment';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { SMSTemplateDto } from '@core/services/dto/sms-template.dto';
import { CreateTaskStateService } from '@shared/components/create-task/create-task.state.service';

@Component({
  selector: 'app-add-task-to-conversation',
  templateUrl: './add-task-to-conversation.component.html',
  styleUrls: [
    '../create-conversation/create-conversation.component.scss',
    './add-task-to-conversation.component.scss',
  ],
})
export class AddTaskToConversationComponent
  extends autoUnsubscribeMixin()
  implements OnInit {
  @Output() addingTask: EventEmitter<boolean> = new EventEmitter();
  @Output() taskChange: EventEmitter<TaskCreateInterface> = new EventEmitter();

  addingTaskState = false;
  formTask: FormGroup;
  defaultAssignee = new Subject<SearchMemberOptionsModel>();
  taskTemplatesOptions: SelectOption<number>[] = [];
  taskTemplates: TaskTemplateDto[] = [];
  taskEmails: EmailTemplateDto[] = [];
  taskSMS: SMSTemplateDto[] = [];
  emailTemplates: number[] = [];
  smsTemplates: number[] = [];

  taskUser: UserNameModel;
  todayDate = new Date();
  taskUsers$!: Observable<SearchUserOptionsModel[]>;
  selectedTaskUser: any;
  FileTypeEnum = FileTypeEnum;
  taskTypeOptions = TASK_TYPE_OPTIONS;
  taskPriorityOptions = TASK_PRIORITY_OPTIONS;
  GLOBAL_SETTINGS = GLOBAL_SETTINGS;
  isPanelClosed = false;

  private taskDefaultType = TaskTypeEnum[TaskTypeEnum.General];
  private taskDefaultPriority = TaskPriorityEnum[TaskPriorityEnum.Low];
  private isDefaultAssignerSet = false;

  @ViewChild('taskAttachments') taskAttachments: UploadFileComponent;
  @ViewChild(MatAutocompleteTrigger) matAutocompleteTrigger: MatAutocompleteTrigger;

  constructor(
    private formBuilder: FormBuilder,
    private state: CreateConversationStateService,
    private stateTask: CreateTaskStateService,
    private globalState: GlobalStateService,
    private htmlElementRef: ElementRef
  ) {
    super();
    this.taskUsers$ = this.state.taskUsers$();
    this.formTask = this.formBuilder.group({
      templateId: [null],
      type: [this.taskDefaultType, Validators.required],
      priority: [this.taskDefaultPriority, Validators.required],
      description: [
        '',
        [
          Validators.maxLength(1000),
          Validators.minLength(3),
          Validators.required,
        ],
      ],
      dueDate: [''],
      assigneeId: new AssigneeFormControl('', Validators.required),
    });
  }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scrollEvent, true);
    this.formTask.controls.assigneeId.valueChanges.subscribe((name) => {
      if (!name) {
        return;
      }

      if (
        name?.length >= GLOBAL_SETTINGS.minLengthToTriggerSearch &&
        this.isDefaultAssignerSet
      ) {
        this.state.getSearchedUserGroups(name, false);
      }
    });

    this.formTask.controls.templateId.valueChanges.subscribe((value) => {
      this.applyTaskTemplate(value);
    });

    this.formTask.valueChanges.subscribe((values) => {
      let task: TaskCreateInterface = null;
      if (this.addingTaskState && this.formTask.valid) {
        task = {
          type: values.type,
          priority: values.priority,
          description: values.description,
          dueDate: values.dueDate,
          assigneeId: values.assigneeId?.ref,
          emails: this.emailTemplates,
          sms: this.smsTemplates,
          templateId: values.templateId,
          attachments: values.attachments.map(x => x.id),
        };
      }

      this.taskChange.emit(task);
    });

    this.state.emailTemplates$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        this.taskEmails = response.data;
        this.emailTemplates = this.taskEmails.map((e) => e.id);
      });
      this.state.taskTemplates$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((taskTemplates) => {
        taskTemplates.length
          ? this.initializeTaskTemplatesDropdown(taskTemplates)
          : this.initializeEmptyTaskTemplatesDropdown();
      });
      this.stateTask.smsTemplates$
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(response => {
        this.taskSMS = response.data;
        this.smsTemplates = this.taskSMS.map(e => e.id);
      });
  }

  chooseTaskUser(data): void {
    this.taskUser.id = data.ref;
  }

  addEmail(selectedIds): void {
    this.emailTemplates = selectedIds;
    this.formTask.controls.emails=selectedIds;
  }
  addSMS(selectedIds): void {
    this.smsTemplates = selectedIds;
    this.formTask.controls.sms=selectedIds;

  }
  isEmailType(): boolean {
    return this.formTask.controls.type.value === 'Email';
  }
  isSMSType(): boolean {
    return this.formTask.controls.type.value === 'SMS';
  }
  clearTaskForm(): void {
    this.addingTaskState = !this.addingTaskState;
    this.addingTask.emit(this.addingTaskState);

    this.formTask.patchValue({
      type: TaskTypeEnum[TaskTypeEnum.General],
      priority: TaskPriorityEnum[TaskPriorityEnum.Low],
      description: '',
      dueDate: '',
    });

    this.setDefaultTaskAssigneeForTask();
  }

  private applyTaskTemplate(taskTemplateId: number): void {
    if (!!taskTemplateId) {
      const taskTemplate = this.taskTemplates.find(
        (template) => template.id === taskTemplateId
      );
      // @ts-ignore
      const templateDueDate = moment(moment(new Date())).add(
        taskTemplate.timeDue.dueIn,
        taskTemplate.timeDue.dueDateType
      );

      if (taskTemplate.attachments.length) {
        taskTemplate.attachments.forEach((file) => {
          this.taskAttachments.createFormGroup(file);
          this.taskAttachments.addFileToAttachment(file);
        });
      }

      if (taskTemplate.emails.length) {
        const emailIds = taskTemplate.emails.map((x) => x.id.toString());
        this.state.getEmailTemplates(emailIds);
      }
      if (taskTemplate.sms.length) {
        const sms = taskTemplate.sms.map((x) => x.id.toString());
        this.state.getsmsTemplates(sms);
      }

      this.formTask.patchValue({
        type: taskTemplate.type,
        priority: taskTemplate.priority,
        description: taskTemplate.description,
        dueDate: templateDueDate.utc().toISOString(),
        assigneeId: taskTemplate.assigneeId,
      });

      if (taskTemplate.assigneeId) {
        this.selectedTaskUser = {
          label: taskTemplate.assigneeId.isGroup === true ? taskTemplate.assigneeId.name :
            taskTemplate.assigneeId.firstName +
            ' ' +
            taskTemplate.assigneeId.lastName,
          ref: taskTemplate.assigneeId.id,
          type: taskTemplate.assigneeId.type,
          value: null,
        };
      }
    } else {
      this.formTask.patchValue({
        type: this.taskDefaultType,
        priority: this.taskDefaultPriority,
        description: null,
        dueDate: null,
      });
      this.taskAttachments?.clear();
      this.taskEmails = [];
      this.taskSMS = [];

    }
  }

  private initializeTaskTemplatesDropdown(
    taskTemplates: TaskTemplateDto[]
  ): void {
    const taskTemplatesOptions = [NoneTaskTemplateDropdownOption].concat(
      taskTemplates.map<SelectOption<number>>((taskTemplate) => ({
        label: taskTemplate.name,
        value: taskTemplate.id,
      }))
    );

    this.taskTemplatesOptions = taskTemplatesOptions;
    this.taskTemplates = taskTemplates;

    this.formTask.controls.templateId.patchValue(
      NoneTaskTemplateDropdownOption.value
    );
  }

  private initializeEmptyTaskTemplatesDropdown(): void {
    this.taskTemplatesOptions = [EmptyTaskTemplateDropdownOption];
  }

  private setDefaultTaskAssigneeForTask(): void {
    this.selectedTaskUser = {
      label:
        this.globalState.user.firstName + ' ' + this.globalState.user.lastName,
      ref: this.globalState.user.id,
      type: null,
      value: null,
    };
    this.isDefaultAssignerSet = true;
  }
  getOptionText(option): any {
    return option?.label;
  }

  private scrollEvent = (event: any): void => {
    if (this.matAutocompleteTrigger.panelOpen) {
      this.matAutocompleteTrigger.updatePosition();
    }
    const selectedElement = this.htmlElementRef.nativeElement.querySelector('.assignedId-container');

    if (selectedElement && (this.isPanelClosed || this.matAutocompleteTrigger.panelOpen)) {
      const dialogContainer = document.querySelector('.mat-dialog-container');
      if (dialogContainer) {
        const dialogContainerRect = dialogContainer.getBoundingClientRect();
        const dialogContainerTop = dialogContainerRect.top;

        const panelElement = document.querySelector('.mat-autocomplete-panel');
        let panelHeight = panelElement ? panelElement.getBoundingClientRect().height : 96;
        panelHeight = panelHeight > 180 ? panelHeight - 40 : panelHeight;
        const dialogContainerBottom = dialogContainerRect.bottom - panelHeight;

        const selectedElementRect = selectedElement.getBoundingClientRect();
        const selectedElementTop = selectedElementRect.top;
        const selectedElementBottom = selectedElementRect.bottom;
        const isOutsideDialogBounds = selectedElementTop < 100;

        if (this.matAutocompleteTrigger.panelOpen && isOutsideDialogBounds) {
          this.isPanelClosed = true;
          this.matAutocompleteTrigger.closePanel();
        } else if (!this.matAutocompleteTrigger.panelOpen && !isOutsideDialogBounds && this.isPanelClosed) {
          this.matAutocompleteTrigger.openPanel();
          this.isPanelClosed = false;
        }
      }
    }
  };
}
