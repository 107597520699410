import { UserGroupDetailsDto } from '@core/services/dto';
import { formateUTCtoTZ } from '@shared/utils';

export class UserGroupDetailsModel {
  createdBy: string;
  description: string;
  id: number;
  lastModified: string;
  lastModifiedBy: string;
  name: string;
  status: string;
  isHidden?: boolean;
  groupType?: string;
  type?: string;
  constructor(dto: UserGroupDetailsDto) {
    for (const prop in dto) {
      if (dto.hasOwnProperty(prop)) {
        this[prop] = dto[prop];
      }
    }
    this.createdBy = dto.createdBy ? `${dto.createdBy?.firstName} ${dto.createdBy?.lastName}` : '';
    this.lastModified = dto.lastModified ? formateUTCtoTZ(dto.lastModified) : dto.lastModified;
    this.lastModifiedBy = dto.lastModifiedBy ? `${dto.lastModifiedBy?.firstName} ${dto.lastModifiedBy?.lastName}` : '';
  }
}
