export enum BusinessTypeEnum {
  SoleProprietorship = 1,
  Partnership = 2,
  Association = 3,
  Corporation = 4,
}

export const BusinessTypeStringEnum = {
  SoleProprietorship: 'SoleProprietorship',
  Partnership: 'Partnership',
  Association: 'Association',
  Corporation: 'Corporation ',
};
