import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SalesProductDto } from '@core/services/dto';
import { GlobalStateService } from '@core/services/global.state.service';
import { CampaignsModel, SaleCreateInterface } from '@shared/models';
import { SearchMemberProspectOptionsModel } from '@shared/models/search.member.prospect.options.model';
import { UserBranchModel } from '@shared/models/user-Branch.model';

@Component({
  selector: 'app-add-sale-to-conversation',
  templateUrl: './add-sale-to-conversation.component.html',
  styleUrls: ['../create-conversation/create-conversation.component.scss', './add-sale-to-conversation.component.scss']
})
export class AddSaleToConversationComponent implements OnInit {
  @Output() addingSale: EventEmitter<boolean> = new EventEmitter();
  @Output() saleChange: EventEmitter<SaleCreateInterface> = new EventEmitter();

  addingSaleState = false;
  formSale: FormGroup;
  products: SalesProductDto[] = [];
  productsList = [];
  referrers = [];
  campaignOptions: CampaignsModel[];
  userBranchOptions: UserBranchModel[];
  defaultBranch: UserBranchModel;

  constructor(private formBuilder: FormBuilder, private globalState: GlobalStateService) {
    this.formSale = this.formBuilder.group({
      applicationId: [null],
      campaignId: [null],
      branchId: [null],
    });
  }

  ngOnInit(): void {
    this.globalState.getBranch();
    this.globalState.userBranches$().subscribe({
      next: (data) => {
        this.userBranchOptions = data;
        this.defaultBranch = this.userBranchOptions.find(branch => branch.isDefault === true);
        this.formSale.patchValue({
          branchId: this.defaultBranch?.value
        });
      },
      error: (error) => {
        console.error('Error:', error);
      }
    });
    this.globalState.campaigns$().subscribe(data => {
      this.campaignOptions = [...data];

      this.campaignOptions.push({
        value: -1,
        label: 'N/A'
      });
    });
    this.formSale.valueChanges.subscribe(values => {
      this.saleUpdated(values.applicationId, values.campaignId, values.branchId);
    });
  }

  addProduct(): void {
    this.productsList.push(Math.floor(Math.random() * 1000));
  }

  addReferrerInstance(links: SearchMemberProspectOptionsModel[]): void {
    const acc = [];
    links.forEach(item => {
      if (item.ref) {
        acc.push(item.ref);
      }
    });
    this.referrers = acc;
  }

  delete(id): void {
    this.productsList = this.productsList.filter(item => item !== id);
    this.products = this.products.filter(item => item.tempId !== id);
  }

  saveProduct(product): void {
    this.products = this.products.filter(item => item.tempId !== product.tempId);
    this.products.push(product);

    this.saleUpdated(this.formSale.value.applicationId, this.formSale.value.campaignId, this.formSale.value.branchId);
  }

  clearFormSale(): void {
    this.addingSaleState = !this.addingSaleState;
    this.addingSale.emit(this.addingSaleState);
    this.formSale.patchValue({
      applicationId: null,
      campaignId: null,
    });
    this.products = [];
    this.referrers = [];
  }

  private saleUpdated(applicationId: string, campaignId: number, branchId: number): void {
    let sale: SaleCreateInterface = null;

    if (this.addingSaleState && this.formSale.valid && !!this.products.length) {
      sale = {
        applicationId,
        campaignId: campaignId === -1 ? null : campaignId,
        branchId: branchId === -1 ? null : branchId,
        products: this.products,
        referrers: this.referrers
      };
    }

    this.saleChange.emit(sale);
  }
}
