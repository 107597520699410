import { InjectionToken } from '@angular/core';

export let APP_CONFIG = new InjectionToken('app.config');

export const AppConfig: any = {
  routes: {
    dashboard: 'dashboard',
    home: 'home',
    conversations: 'conversations',
    versionings: 'version-detail',
    memberProfile: {
      main: 'member-profile',
      conversations: 'conversations',
      tasks: 'tasks',
      notes: 'notes',
      info: 'info',
      contact: 'contact',
      accounts: 'accounts',
      sales: 'sales',
      documents: 'documents',
      account: (id: number, subType: string) => `${subType}/${id}`,
      profile: 'profile',
      offers: 'offers',
      access_history: 'access-history',

    },
    prospectProfile: {
      main: 'prospect-profile',
      conversations: 'conversations',
      tasks: 'tasks',
      notes: 'notes',
      info: 'info',
      contact: 'contact',
      accounts: 'accounts',
      documents: 'documents',
      profile: 'profile',
      access_history: 'access-history',

      // offers: 'offers',
    },
    elements: 'elements',
    error404: '404',
    memberManagement: {
      search: 'search',
      tasks: 'tasks',
      conversations: 'conversations',
      importConversation: 'import-conversation',
      conversationDetails: (id: number) => `conversations/${id}/edit`,
      conversationDetailsView: (id: number) => `conversations/${id}`,
      opportunities: 'opportunities',
      importOpportunities: 'import-opportunities',
      assignedApplications: 'assigned-applications',
      recentApplications: 'recent-applications',
      concerns: 'concerns',
      importConcern: 'import-concern',
      recentProfiles: 'recent-profiles',
      announcements: 'user-announcements',
      sales: 'sales',
      salesDetailsView: (id: number) => `sales/${id}`,
      salesDetails: (id: number) => `sales/${id}/edit`,
      importoffersdetail: 'import-offers-detail',
      importoffers: 'import-offers',
      offers: 'offers',
      OfferDetails: (id: number) => `offers/${id}/edit`,
      sqlBuilder: 'opportunity-engine',
    },
    contentManagement: {
      taskTemplates: 'task-templates',
      workflowTemplates: 'workflow-templates',
      emailTemplates: 'email-templates',
      campaignManagement: 'campaign-management',
      announcements: 'view-announcements',
      publicLinks: 'public-links',
      privateLinks: 'private-links',
      tagManagement: 'tag-management',
      ecm: 'ecm',
      offerTemplates: 'offer-templates',
    },
    setup: {
      themeBuilder: 'theme-builder',
      userManagement: 'user-management',
      userManagementDetails: (id: string) => `user-management/${id}`,
      userGroupManagement: 'user-group-management',
      userInformation: 'user-information',
      approvelModels: 'approval-models',
      rateModels: 'rate-models',
    },
    reports: {
      root: 'reports',
      saleReporting: 'reports/sale-reporting',
      opportunityReporting: 'reports/opportunity-reporting',
      pipelineReporting: 'reports/pipeline-reporting',
      interactionReporting: 'reports/interaction-reporting',
    },
  },
};
