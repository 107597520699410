import { Injectable } from '@angular/core';
import { ApiClient } from '@core/services/api-client.http.service';
import { EmailNotificationUrls } from '@core/constants';
import { Observable } from 'rxjs';
import { EmailNotificationDto } from '../dto/email-notification.dto';

@Injectable({
  providedIn: 'any',
})
export class EnailNotificationApiService {
  constructor(private api: ApiClient) { }

  getEmailNotificationPermission(hasGroup: boolean): Observable<EmailNotificationDto[]> {
    return this.api.get<EmailNotificationDto[]>(EmailNotificationUrls.emailNotifications(hasGroup));
  }

  updateEmailNotificationPermission(request): Observable<any[]> {
    return this.api.put<EmailNotificationDto[]>(
      EmailNotificationUrls.emailNotification,
      request
    );
  }
}
