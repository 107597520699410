export enum BusinessRoleTypeEnum {
  Owner = 1,
  Partner = 2,
  SigningOfficer = 3,
  BeneficialOwner = 4,
  Director  = 5,
}

export const BusinessRoleTypeStringEnum = {
  Owner: 'Owner',
  Partner: 'Partner',
  SigningOfficer: 'Signing Officer',
  BeneficialOwner: 'BeneficialOwner ',
  Director: 'Director ',
};
