import { Pipe, PipeTransform } from '@angular/core';
import { GroupTypeEnum } from '@shared/enums/group-type.enum';
import { Observable, of } from 'rxjs';

@Pipe({name: 'GroupTypePipe'})
export class GroupTypePipe implements PipeTransform {
  transform(value: string): Observable<string> {
    let formattedValue;
    if (value === GroupTypeEnum[GroupTypeEnum.All]) {
      formattedValue = 'CRM Assignment, ECM Folder Permission and Representative Role';
    } else {
      formattedValue = value;
    }

    return of(formattedValue);
  }
}
