import { MemberContactPreferencesDto } from '@core/services/dto/member-contact-preferences.dto';

export class MemberContactPreferencesModel {
  frequency: string;
  method: string;
  time: string;
  consent: boolean;

  constructor(dto: MemberContactPreferencesDto) {
    this.frequency = dto.frequency;
    this.method = dto.method;
    this.time = dto.time;
    this.consent = dto.consent;
  }
}
