import { ConversationDto, ProductDto, UserDto } from '@core/services/dto';
import { LinkModel } from '@shared/models/link.model';
import { AttachmentModel } from '@shared/models/attachment.model';
import { SaleModel } from '@shared/models/sale.model';
import { ReferrerModel } from './Referrer.model';
import { ConcernTypeDto } from '@core/services/dto/concern-type.dto';

export class ConversationModel {
  id: number;
  conversationType: string;
  communicationType: string;
  subject: string;
  description: string;
  links: LinkModel[];
  sale?: SaleModel;
  status: string;
  created: string;
  closedDate: string;
  product: ProductDto;
  productId?: number;
  concernType: ConcernTypeDto;
  resolution: string;
  resolutionDetails: string;
  lastInteraction: string;
  attachments: AttachmentModel[];
  createdBy: {
    userId: string;
    firstName: string;
    lastName: string;
  };
  assignee?: {
    firstName: string,
    lastName: string,
    id: string,
    name: string,
    isGroup: boolean,
    status: string,
    type: string

  };
  assigneeId?: string;
  fullName?: string;
  declineReason?: string;
  expiryDate?: string;
  importFileName?: string;
  importId?: number;
  expectedValue?: number;
  opportunityProbability?: string;
  expectedCloseDate?: string;
  campaign?: {
    id: number;
    name: string
  };
  campaignId?: number;
  referrers: ReferrerModel[];
  emails: [];
  message?: string;

  constructor(dto: ConversationDto) {
    this.id = dto.id;
    this.importId = dto.importId;
    this.conversationType = dto.conversationType;
    this.communicationType = dto.communicationType;
    this.subject = dto.subject;
    this.description = dto.description;
    this.links = dto.links;
    this.status = dto.status;
    this.declineReason = dto.declineReason;
    this.expiryDate = dto.expiryDate;
    this.created = dto.created;
    this.lastInteraction = dto.lastInteraction;
    this.createdBy = dto.createdBy;
    this.closedDate = dto.closedDate;
    this.product = dto.product;
    this.concernType = dto.concernType;
    this.resolution = dto.resolution;
    this.fullName = dto.assignee ? `${dto.assignee?.firstName} ${dto.assignee?.lastName}` : '';
    this.resolutionDetails = dto.resolutionDetails;
    this.attachments = dto.attachments;
    this.assigneeId = dto.assigneeId;
    this.expectedValue = dto.expectedValue;
    this.opportunityProbability = dto.opportunityProbability;
    this.expectedCloseDate = dto.expectedCloseDate;
    this.campaign = dto.campaign;
    this.campaignId = dto.campaign?.id;
    this.assignee = dto.assignee;
    this.referrers = dto.referrers;
    this.emails = dto.emails;
  }
}
