import { ConversationModel } from '@shared/models/index';
import { SearchConversationDto } from '@core/services/dto/search-conversation.dto';

export class SearchConversationModel {
  data: ConversationModel[];
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  pageNumber: number;
  totalCount: number;
  totalPages: number;

  constructor(dto: SearchConversationDto) {
   this.data = dto.data;
   this.hasNextPage = dto.hasNextPage;
   this.hasPreviousPage = dto.hasPreviousPage;
   this.pageNumber = dto.pageNumber;
   this.totalCount = dto.totalCount;
   this.totalPages = dto.totalPages;
  }
}
