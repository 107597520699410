import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { MemberProfileApiService, PersonApiService } from '@core/services/api.services';
import { PaginatedResponseDto } from '@core/services/dto';
import { ProvinceModel } from '@shared/models';
import { CanadaPostAddressApiService } from '@core/services/api.services/canana-post-address-api.service';
import { CanadaPostAddressFindDto } from '@core/services/dto/canada-post-address-find.dto';
import { CanadaPostAddressRetrieveDto } from '@core/services/dto/canada-post-address-retrieve.dto';
import { CanadaPostAddressSearchModel } from '@shared/components/canada-post-address/models/canada-post-address-search.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CanadaPostAddressStateService {

  countryCode = new BehaviorSubject('');
  private addressesSubject: Subject<CanadaPostAddressFindDto[]> = new Subject<CanadaPostAddressFindDto[]>();
  public addresses$: Observable<CanadaPostAddressSearchModel[]> = this.addressesSubject.asObservable()
    .pipe(
      map(data => data.map(x => ({
        value: x.id,
        label: `${x.text} ${x.description}`,
        next: x.nextOperation,
      }))));

  constructor(private canadaPostAddressApiService: CanadaPostAddressApiService,
              private memberProfileApiService: MemberProfileApiService,
              private personApiService: PersonApiService
              ) { }

  getProvinces(): Observable<PaginatedResponseDto<ProvinceModel>> {
    return this.memberProfileApiService.getProvinces();
  }

  searchAddress(searchAddr: string, id: string, IsGoogleActivated: boolean, countryCode: string): void {
    this.canadaPostAddressApiService.searchAddress(searchAddr, id, IsGoogleActivated, countryCode)
      .subscribe((data: CanadaPostAddressFindDto[]) => {
        this.addressesSubject.next(data);
      });
  }

  retrieveAddress(id: string, IsGoogleActivated: boolean, countryCode: string): Observable<CanadaPostAddressRetrieveDto[]> {
    return this.canadaPostAddressApiService.retrieveAddress(id, IsGoogleActivated, countryCode);
  }

  getAutoComplete(): Observable<any> {
    return this.personApiService.getAutoComplete();
  }
}
