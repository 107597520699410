import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';

@Pipe({name: 'LeadingZerosPipe'})
export class LeadingZerosPipe implements PipeTransform {
  transform(value: number): Observable<string> {
    let formattedValue;
    if (value < 10) {
      formattedValue = `0000${value}`;
    } else if (value < 99) {
      formattedValue = `000${value}`;
    } else if (value > 100) {
      formattedValue = `00${value}`;
    } else if (value > 999) {
      formattedValue = `0${value}`;
    }

    return of(formattedValue);
  }
}
