import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserManagementApiService } from '@core/services/api.services';
import { UserGroupDetailsModel, UserManagementModel } from '@shared/models';
import { filter, map } from 'rxjs/operators';
import {
  PaginatedResponseDto,
  UserGroupDetailsDto,
  UserManagementUsersDto,
} from '@core/services/dto';
import { CustomSnackbarService } from '@core/services/custom-snackbar.service';
import { AuthService } from '@core/services/auth';
import { UserOfferUpdateApiRequest } from '@shared/models/requests/user-offer-update.request.dto';
import { getOfferApiBase } from '@config/org.config';

const offerBase = getOfferApiBase();

@Injectable({ providedIn: 'any' })
export class UserManagementStateService {
  private users: BehaviorSubject<PaginatedResponseDto<UserManagementModel>> =
    new BehaviorSubject(null);

  public users$: Observable<PaginatedResponseDto<UserManagementModel>> =
    this.users.asObservable().pipe(filter((x) => !!x));

  private usersOffer: BehaviorSubject<
    PaginatedResponseDto<UserManagementModel>
  > = new BehaviorSubject(null);

  public usersOffer$: Observable<PaginatedResponseDto<UserManagementModel>> =
    this.usersOffer.asObservable().pipe(filter((x) => !!x));

  private user: BehaviorSubject<UserManagementModel> = new BehaviorSubject(
    null
  );

  public user$: Observable<UserManagementModel> = this.user
    .asObservable()
    .pipe(filter((x) => !!x));

  private permission: BehaviorSubject<number[]> = new BehaviorSubject([]);

  public permission$: Observable<number[]> = this.permission
    .asObservable()
    .pipe(filter((x) => !!x));

  constructor(
    private service: UserManagementApiService,
    private authService: AuthService,
    private toast: CustomSnackbarService
  ) {}

  getData(request?): void {
    this.service
      .getData(request)
      .pipe(
        map((response: PaginatedResponseDto<UserManagementUsersDto>) => {
          return {
            ...response,
            data: response.data.map((item) => new UserManagementModel(item)),
          };
        })
      )
      .subscribe((data) => {
        this.users.next(data);
      });
  }

  getOfferData(request?): void {
    if (offerBase !== undefined) {
      this.service
        .getOfferData(request)
        .pipe(
          map((response: PaginatedResponseDto<UserManagementUsersDto>) => {
            return {
              ...response,
              data: response.data.map((item) => new UserManagementModel(item)),
            };
          })
        )
        .subscribe((data) => {
          this.usersOffer.next(data);
        });
    } else {
      this.usersOffer.next(null);
    }
  }
  savePermission(id, permissions): Observable<UserManagementUsersDto> {
    return this.service.saveUserPermission(id, permissions);
  }

  updateUser(id, request): Observable<UserManagementUsersDto> {
    return this.service.updateUserId(id, request);
  }

  getUserById(id): void {
    this.service
      .getUserId(id)
      .pipe(
        map(
          (response: UserManagementUsersDto) =>
            new UserManagementModel(response)
        )
      )
      .subscribe((data: UserManagementModel) => {
        this.user.next(data);
      });
  }

  getPermissions(id): void {
    this.service.getPermissions(id).subscribe((response) => {
      this.permission.next(response.data);
    });
  }

  getUserGroups(id): Observable<PaginatedResponseDto<UserGroupDetailsModel>> {
    return this.service.getUserGroups(id).pipe(
      map((response: PaginatedResponseDto<UserGroupDetailsDto>) => {
        return {
          ...response,
          data: response.data.map((item) => new UserGroupDetailsModel(item)),
        };
      })
    );
  }

  getGroups(request?): Observable<PaginatedResponseDto<UserGroupDetailsModel>> {
    return this.service.getGroups(request).pipe(
      map((response: PaginatedResponseDto<UserGroupDetailsDto>) => {
        return {
          ...response,
          data: response.data.map((item) => new UserGroupDetailsModel(item)),
        };
      })
    );
  }

  saveGroups(id, groupIds): void {
    this.service.saveUserGroups(id, groupIds).subscribe(() => {
      this.toast.success('User group was successfully updated');
      // window.location.reload();
    });
  }

  clear(): void {
    this.user.next(null);
    this.permission.next([]);
  }

  createFolder(request): void {
    return this.service.createFolder(request);
  }

  sendActivation(request): void {
    this.service.sendActivation(request).subscribe(() => {
      this.toast.success('User Activation was successfully send');
      window.location.reload();
    });
  }
  enableDisableOfferUser(request: UserOfferUpdateApiRequest): any {
    if (offerBase !== undefined) {
      return this.service.enableDisableOfferUser(request);
    } else {
      return null;
    }
  }
}
