import { formatDateSimple } from '@shared/utils';
import { SalesModel } from '@shared/models/sales.model';

export class SalesProductModel {
  value: number;
  id: number;
  saleType: string;
  fundSource: string;
  // we can't use here SoldBy interface,
  // cause we need id instead of UserId to make input autocomplete component work
  soldBy: {
    firstName: string;
    lastName: string;
    id?: string;
  };
  soldByUser: string;
  renewalDate: Date;
  productId: number;
  productName: string;
  productType: number;
  productTypeName: string;
  tempId?: number;
  actualValue?: number;
  maturityDate?: Date;

  constructor(dto: SalesModel) {
    this.id = dto.id;
    this.renewalDate = dto.renewalDate;
    this.fundSource = dto.fundSource;
    this.saleType = dto.saleType;
    this.soldBy = dto.soldBy;
    this.soldBy.id = dto.soldBy.userId;
    this.soldByUser = dto.soldByUser;
    this.value = dto.value;
    this.productType = dto.product?.type?.id;
    this.productTypeName = dto.product?.type?.name;
    this.productName = dto.product?.label;
    this.productId = dto.product?.value;
    this.actualValue = dto.actualValue;
    this.maturityDate = dto.maturityDate;
  }
}
