export enum MemberTypeEnum {
  Individual = 1,
  Business = 2,
  All = 3,
  IndividualProspect = 4,
  BusinessProspect = 5
}

export const MemberTypeStringEnum = {
  Individual: 'Individual',
  Business: 'Business',
  IndividualProspect: 'Individual Prospect',
  BusinessProspect: 'Business Prospect',
};
