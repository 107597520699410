<div *ngFor="let item of data">
  <div class="header">
    <app-accordion-row-item (iconAction)="onIconHandler($event, item)"
                            [head]="item.head"
                            (clickAction)="emitClick($event)"
                            [hasChildren]="item.data?.length"
                            [tableConfig]="tableConfig"
                            [data]="[item.values]">
    </app-accordion-row-item>
  </div>

  <div class="content" *ngIf="item.visible"
       [ngClass]="item.visible ? 'collapse' : 'expand'">
    <app-accordion-row-builder (clickAction)="emitClick($event)"
                               [tableConfig]="tableConfig"
                               [data]="item.data">
    </app-accordion-row-builder>
  </div>
</div>


