import { CollateralDto } from '@core/services/dto';

export class CollateralModel {
  assetType: string;
  description: string;
  value: number;
  unit: number;
  priority: string;
  status: string;

  constructor(dto: CollateralDto) {
    this.assetType = dto.assetType;
    this.description = dto.description;
    this.value = dto.value;
    this.priority = dto.priority;
    this.status = dto.status;
    this.unit = dto.unit;
  }
}
