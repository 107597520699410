import { MemberProfileModel } from './member-profile.model';

export class SearchMemberProspectOptionsModel {
  label?: string;
  value?: string;
  genre?: string;
  ref: any;
  type: string;
  disabled?: boolean;
  memberNumber?: string;
  isProspectInfo?: boolean;
  cellPhone?: string;

  constructor(dto: MemberProfileModel) {
    if (dto.memberInfo.organizationName) {
      this.label = `${dto.memberNumber} - ${dto.memberInfo.organizationName}`;
      this.value = dto.memberInfo.organizationName;
    } else {
      this.label = `${dto.memberNumber} - ${dto.memberInfo.firstName} ${dto.memberInfo.lastName}`;
      this.value = `${dto.memberInfo.firstName} ${dto.memberInfo.lastName}`;
    }
    this.type = dto.isProspectInfo === false ? 'member' : 'prospect';
    this.genre = dto.memberType;
    this.ref = dto.memberId?.toString();
    this.memberNumber = dto.memberNumber;
    this.isProspectInfo = dto.isProspectInfo;
    this.cellPhone =dto.cellPhone;
  }
}
