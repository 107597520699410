import { LinkModel } from '@shared/models';

export class ConversationCreateModel {
  status: string;
  subject: string;
  description: string;
  productId: number;
  links: LinkModel[];
  attachments: number[];
  assigneeId: string;
}
