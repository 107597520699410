export interface AccountDetailsModel {
  accountId: number;
  accountNumber: string;
  memberId: number;
  name: string;
  openedDate: string;
  status: string;
  subType: string;
  type: string;
  memberName?: string;
}
