import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  MemberProfileDto,
  TasksSummaryDto,
  PaginatedResponseDto,
  SearchMemberDto,
  PersonDto,
  AddressDto,
  PeopleDto,
  BranchDto, OrganizationDto, HoldDto, CollateralDto, SavingDto, LendingDto, MemberBankingDto, MemberSyncDto
} from '@core/services/dto';
import { MemberUrls, NoteUrls, ProspectUrls } from '@core/constants/endpoints.constants';
import { ApiClient } from '@core/services/api-client.http.service';
import { NoteDto } from '@core/services/dto/note.dto';
import { AddPersonToOrganizationApiRequestModel } from '@shared/models/requests/add-person-to-organization.request.dto';
import { MemberProfileRequestDto } from '@shared/models/requests/member-profile.request.dto';
import { LinkSearchRequestDto } from '@shared/models/requests/link-search.request.dto';
import { AddressModel, OrganizationModel, PeopleModel, PersonModel, ProvinceModel } from '@shared/models';
import { PeopleApiRequest } from '@shared/models/requests/people.request.dto';
import { PersonUpdateApiRequestModel } from '@shared/models/requests/person-update.request.dto';
import { MergeProspectModel } from '@shared/models/merge-prospect.model';
import { ProspectCreateDto } from '../dto/prospect-create.dto';
import { ProspectCreate } from '@shared/models/prospect-create';
import { ProspectContactDto } from '../dto/prospect-contact.dto';
import { ProspectContactPreferencesModel } from '@shared/models/prospect-contact-preferences.model';
import { ProspectContactInfoDto } from '../dto/prospect-contact-info.dto';
import { ProspectContactInfoModel } from '@shared/models/prospect-contact-info.model';
import { AccessHistoryApiRequest } from '@shared/models/requests/accesshistory.request.dto';
import { AccessHistoryDto } from '../dto/accesshistory.dto';

@Injectable({
  providedIn: 'any',
})
export class ProspectProfileApiService {
  constructor(private api: ApiClient) { }

  getProspectProfileInfo(memberId: number): Observable<MemberProfileDto> {
    return this.api.get<MemberProfileDto>(ProspectUrls.prospect(memberId));
  }

  getMembers(
    request: MemberProfileRequestDto
  ): Observable<PaginatedResponseDto<MemberProfileDto>> {
    return this.api.get<PaginatedResponseDto<MemberProfileDto>>(
      MemberUrls.members,
      request
    );
  }

  // eslint-disable-next-line
  getCoreBanking(
    memberNumber: number,
    branchId: number,
    request: MemberProfileRequestDto
  ): Observable<PaginatedResponseDto<MemberBankingDto>> {
    let url = MemberUrls.coreBanking(memberNumber);

    if (!!branchId) {
      url += `&branchId=${branchId}`;
    }

    return this.api.get<PaginatedResponseDto<MemberBankingDto>>(url, request);
  }

  searchMember(request?: LinkSearchRequestDto): Observable<SearchMemberDto> {
    return this.api.get<SearchMemberDto>(MemberUrls.members, request);
  }

  status(prospectId: number, prospectStatus: string): Observable<MemberProfileDto> {
    return this.api.put(ProspectUrls.Status(prospectId, prospectStatus));
  }

  getPerson(personId: number): Observable<ProspectCreateDto> {
    return this.api.get<ProspectCreateDto>(ProspectUrls.prospectInformation(personId));
  }

  MergeProspect(prospect: MergeProspectModel): Observable<MergeProspectModel> {
    return this.api.post<MergeProspectModel>(
      ProspectUrls.mergeProspect, prospect
    );
  }

  getPersonAddresses(
    personId: number,
    type: string
  ): Observable<PaginatedResponseDto<AddressDto>> {
    return this.api.get<PaginatedResponseDto<AddressDto>>(
      ProspectUrls.prospectaddresses(personId, type)
    );
  }

  updateMember(id: number, person: PersonModel): Observable<PersonDto> {
    return this.api.put<PersonModel>(MemberUrls.person(id), person);
  }

  importMember(
    memberNumber: string,
    branch: number,
    memberType: string,
    isProspectInfo: boolean
  ): Observable<MemberSyncDto> {
    const params = !!branch ? `?branchId=${branch}` : '';

    return this.api.put<MemberSyncDto>(
      `${ProspectUrls.importProspect(memberNumber, memberType)}${params}`
    );
  }

  importProspectById(memberId: number): Observable<MemberSyncDto> {
    return this.api.put<MemberSyncDto>(ProspectUrls.importProspectById(memberId));
  }

  checkImportProspect(
    memberNumber: string,
    branch: number,
    memberType: string
  ): Observable<MemberSyncDto> {
    const params = {
      branchId: branch,
    };

    return this.api.get<MemberSyncDto>(
      ProspectUrls.importProspect(memberNumber, memberType),
      params,
      null,
      true
    );
  }

  review(memberId: number): Observable<MemberProfileDto> {
    return this.api.put(MemberUrls.review(memberId));
  }

  getBranches(): Observable<PaginatedResponseDto<BranchDto>> {
    return this.api.get(MemberUrls.branches);
  }

  getOrganization(id: number): Observable<OrganizationModel> {
    return this.api.get(MemberUrls.organization(id));
  }

  updateOrganization(
    id: number,
    org: OrganizationModel
  ): Observable<OrganizationDto> {
    return this.api.put<OrganizationModel>(MemberUrls.organization(id), org);
  }


  updateProspecInfo(updateProspecInfo: ProspectCreate): Observable<ProspectCreate> {
    return this.api.put<ProspectCreate>(
      ProspectUrls.updateProspectInformation, updateProspecInfo
    );
  }


  getLendings(accountId: number): Observable<LendingDto> {
    return this.api.get(MemberUrls.lendings(accountId));
  }

  getLendingHolds(
    accountId: number
  ): Observable<PaginatedResponseDto<HoldDto>> {
    return this.api.get(MemberUrls.lendingHolds(accountId));
  }

  getLendingCollateral(
    accountId: number
  ): Observable<PaginatedResponseDto<CollateralDto>> {
    return this.api.get(MemberUrls.lendingCollateral(accountId));
  }

  getSavings(accountId: number): Observable<SavingDto> {
    return this.api.get(MemberUrls.savings(accountId));
  }

  getSavingHolds(accountId: number): Observable<PaginatedResponseDto<HoldDto>> {
    return this.api.get(MemberUrls.savingHolds(accountId));
  }

  getNotes(accountId: number): Observable<PaginatedResponseDto<NoteDto>> {
    return this.api.get(NoteUrls.notes, { accountId, sort: 'desc' });
  }

  syncAccounts(memberId: number): Observable<any> {
    return this.api.put(MemberUrls.syncAccounts(memberId));
  }

  getAllLendings(
    memberId: number
  ): Observable<PaginatedResponseDto<LendingDto>> {
    return this.api.get(MemberUrls.allLendings(memberId));
  }

  getAllSavings(memberId: number): Observable<PaginatedResponseDto<SavingDto>> {
    return this.api.get(MemberUrls.allSavings(memberId));
  }

  getOrganizationPeople(
    memberId: number,
    request: PeopleApiRequest
  ): Observable<PaginatedResponseDto<PeopleDto>> {
    return this.api.get(MemberUrls.people(memberId), request);
  }

  updatePerson(
    jointId: number,
    model: PersonUpdateApiRequestModel
  ): Observable<PeopleDto> {
    return this.api.put<PeopleModel>(MemberUrls.joints(jointId), model);
  }

  addPersonToOrganization(
    primaryMemberId: number,
    request: AddPersonToOrganizationApiRequestModel
  ): Observable<PeopleDto> {
    return this.api.post<PeopleModel>(
      MemberUrls.people(primaryMemberId),
      request
    );
  }

  getProvinces(): Observable<PaginatedResponseDto<ProvinceModel>> {
    return this.api.get(MemberUrls.provinces);
  }

  updateAddress(
    address: AddressModel,
    personId: number,
    addressId: number,
    type: string
  ): Observable<AddressDto> {
    return this.api.put(MemberUrls.address(personId, type, addressId), address);
  }

  createAddress(
    address: AddressModel,
    type: string,
    id: number
  ): Observable<AddressDto> {
    return this.api.post(MemberUrls.createAddress(type, id), address);
  }

  deleteAddress(
    addressId: number,
    id: number
  ): Observable<any> {
    return this.api.delete(ProspectUrls.deleteAddresses(addressId, id));
  }

  getProspectContactPreference(memberId: number): Observable<ProspectContactDto> {
    return this.api.get<ProspectContactDto>(ProspectUrls.prospectContactPreference(memberId));
  }

  updateProspectContactPreference(updateProspectContact: ProspectContactPreferencesModel): Observable<ProspectContactPreferencesModel> {
    return this.api.put<ProspectContactPreferencesModel>(
      ProspectUrls.updateProspectContactPreference, updateProspectContact
    );
  }

  getProspectContact(prospectId: number, contactid: number): Observable<ProspectContactInfoDto> {
    return this.api.get<ProspectContactInfoDto>(ProspectUrls.getProspectContact(prospectId, contactid));
  }

  updateProspectContact(prospectId: number, contactid: number, updateProspecInfo: ProspectContactInfoDto): Observable<ProspectContactInfoDto> {
    return this.api.put<ProspectContactInfoDto>(
      ProspectUrls.updateProspectContact(prospectId, contactid), updateProspecInfo
    );
  }

  createProspectContacts(id: number, contact: ProspectContactInfoModel): Observable<ProspectContactInfoDto> {
    return this.api.post(ProspectUrls.createProspectContacts(id), contact);
  }

  getSummary(
    memberId: number,
    statuses: string[]
  ): Observable<TasksSummaryDto> {
    return this.api.get<TasksSummaryDto>(
      MemberUrls.summary(memberId, statuses, true)
    );
  }

  getProspectProfileAccessHistory(request: AccessHistoryApiRequest): Observable<PaginatedResponseDto<AccessHistoryDto>> {
    return this.api.get<PaginatedResponseDto<AccessHistoryDto>>(ProspectUrls.accessHistory(request.memberId) , request);
  }

}
