import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TagsUrls } from '@core/constants/endpoints.constants';
import { ApiClient } from '@core/services/api-client.http.service';
import { PaginatedResponseDto } from '@core/services/dto';
import { TagsDto } from '@core/services/dto';

@Injectable({
  providedIn: 'root'
})
export class TagManagementApiService {

  constructor(private api: ApiClient) {
  }

  getTags(request): Observable<PaginatedResponseDto<TagsDto>> {
    return this.api.get<PaginatedResponseDto<TagsDto>>(TagsUrls.tags, request);
  }

  addTag(request): Observable<TagsDto> {
    return this.api.post<TagsDto>(TagsUrls.tags, request);
  }

  updateTag(id, request): Observable<TagsDto> {
    return this.api.put<any>(TagsUrls.tag(id), request);
  }

  deleteTag(id): Observable<any> {
    return this.api.delete<any>(TagsUrls.tag(id));
  }

}
