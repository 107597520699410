
export enum TaskStatusEnum {
  Opened = 1,
  Completed = 2,
  Reopened = 3,
  All = 4
}
export const TaskStatusStringEnum = {
  Opened: 'Opened',
  Completed: 'Completed',
  Reopened: 'Reopened'
};
