export const AccountStatusEnum = {
  Active: 'Active',
  Inactive: 'Inactive',
  Closed: 'Closed',
  Dormant: 'Dormant',
  Pending: 'Pending',
  Submit: 'Submit',
  Moreinfo: 'Moreinfo',
  Rejected: 'Rejected',
  Approved: 'Approved',
  Cancelled: 'Cancelled',
  Writeoff: 'Writeoff',
  ClosedWithBalancesRemaining: 'ClosedWithBalancesRemaining',
  LoanDenied: 'LoanDenied'
};
