import { MemberProfileDto } from '@core/services/dto';
import { formatDateSimple, DATE_WITH_TIME_FORMAT, profileDateTimeformate } from '@shared/utils';

export class MemberProfileModel {
  memberId: number;
  memberNumber: string;
  isProspectInfo?: boolean;
  memberType: string;
  householdId?: number;
  memberInfo: {
    id: number;
    firstName?: string;
    lastName?: string;
    dateOfBirth?: string;
    organizationName?: string;
  };
  branch: {
    id: number;
    name: string;
  };
  reviewDate: Date;
  status: string;
  synchronizationResult: {
    syncDate: string,
    successSyncDate: string,
    status: string,
    message: string
  };
  syncStatus?: string;
  syncDate?: string;
  leadType?: string[];
  AddedDate?: string;
  copySyncDate?: string;  // This is similar to syncDate, but it is specifically used to show the date with a timestamp.
  cellPhone?: string;
  constructor(dto: MemberProfileDto) {
    this.memberId = dto?.memberId;
    this.memberNumber = dto.memberNumber;
    this.memberType = dto.memberType;
    this.memberInfo = dto.memberInfo;
    this.branch = dto.branch;
    this.status = dto.status;
    this.householdId = dto.householdId;
    this.synchronizationResult = dto.synchronizationResult;
    this.syncStatus = dto.synchronizationResult?.status?.toLowerCase();
    this.syncDate = !!dto.synchronizationResult?.successSyncDate ?
    profileDateTimeformate(dto.synchronizationResult.successSyncDate, DATE_WITH_TIME_FORMAT) : null;
    this.copySyncDate = !!dto.synchronizationResult?.successSyncDate ?
    dto.synchronizationResult.successSyncDate : null;
    this.reviewDate = dto.reviewDate;
    this.isProspectInfo = dto.isProspectInfo;
    this.leadType = dto.leadType;
    this.AddedDate = dto.memberInfo?.dateOfBirth;
    this.cellPhone = dto.cellPhone;
  }
}
