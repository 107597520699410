import { filter, map, take, takeUntil } from 'rxjs/operators';
import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { GET_MENU_ITEMS } from '@shared/constants/main-menu-items';
import { Router, NavigationEnd } from '@angular/router';
import { autoUnsubscribeMixin } from '@core/helpers/auto-unsubscribe.mixin';
import { environment } from 'environments/environment';
import { AccessService } from '@core/services/access.service';
import { GlobalStateService } from '@core/services/global.state.service';
import { getClientId, getCurrentThemeUrl, getSiteId } from '@config/org.config';
import { Observable, of, zip } from 'rxjs';
import { AuthService } from '@core/services/auth';
import { VersioningApiService } from '@core/services/api.services';
import { OpenedDialogService } from '@core/services/dialog/opened-dialog.service';
import { OpenDialogInterface } from '@shared/models/open-dialog.interface';

@Component({
  selector: 'app-sidebar-left',
  templateUrl: './sidebar-left.component.html',
  styleUrls: ['./sidebar-left.component.scss'],
})
export class SidebarLeftComponent
  extends autoUnsubscribeMixin()
  implements OnInit
{
  menuItems: any[];
  activeTab = 0;
  menuExpanded = false;
  currentUrl: string;
  baseUrl: string;
  version = environment.buildVersion;
  logoSmallUrl = '';
  logoFullUrl = '';
  umbrellaVersion: string;
  environmentName: string;
  isOfferOnly: boolean;
  isOfferAOSOnly: boolean;
  isOfferDisabled: boolean;
  minimizeModals: OpenDialogInterface[] = [];

  @HostListener('document:click', ['$event'])
  clickout(event): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.menuExpanded = false;
    }
  }

  constructor(
    private router: Router,
    private eRef: ElementRef,
    private global: GlobalStateService,
    private auth: AuthService,
    private accessService: AccessService,
    private versioningApiService: VersioningApiService,
    private openedDialogService: OpenedDialogService,
  ) {
    super();
    this.global.offerEnableLogic$.pipe(take(1)).subscribe((value: any) => {
      if (value === 1) {
        this.isOfferOnly = false;
        this.isOfferAOSOnly = false;
        this.isOfferDisabled = true;
      } else if (value === 2) {
        this.isOfferAOSOnly = true;
        this.isOfferOnly = false;
        this.isOfferDisabled = true;
      } else if (value === 3) {
        this.isOfferOnly = true;
        this.isOfferAOSOnly = true;
        this.isOfferDisabled = false;
      } else {
        this.isOfferOnly = true;
        this.isOfferAOSOnly = true;
        this.isOfferDisabled = true;
      }
      this.menuItems = GET_MENU_ITEMS(
        this.global,
        this.auth,
        this.isOfferOnly,
        this.isOfferAOSOnly,
        this.isOfferDisabled
      );
    });
    this.menuItems = GET_MENU_ITEMS(
      this.global,
      this.auth,
      this.isOfferOnly,
      this.isOfferAOSOnly,
      this.isOfferDisabled
    );
  }

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.closeMenu();
      });
    this.checkActiveUrls();

    this.global.themeBuilderType$.pipe(take(1)).subscribe((type) => {
      this.logoSmallUrl = `${this.getLogoLink(type)}/images/client_logo_small`;
      this.logoFullUrl = `${this.getLogoLink(type)}/images/client_logo_full`;
    });

    this.global.VersionVisible$.pipe(take(1)).subscribe((type) => {
      this.environmentName = type;
      this.getUmbrellaVersion(type);
    });

    this.checkMinimizeDialogs();
  }

  checkMinimizeDialogs(): void {
    this.openedDialogService
      .openedDialog$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((dialogs) => {
        this.minimizeModals = dialogs;
      });
  }

  checkActiveUrls(): void {
    this.menuItems.forEach((item) => {
      item.url = typeof item.url === 'function' ? item.url() : item.url;

      if (item.url === this.router.url) {
        this.baseUrl = item.url;
        this.currentUrl = '';
      } else {
        item.child_items?.forEach((child) => {
          if (child.url === this.router.url) {
            this.baseUrl = item.label;
            this.currentUrl = child.url;
          }

          zip(
            this.isItemVisible$(item),
            this.hasAccess$(child, item)
          ).subscribe(([visible, hasAccess]) => {
            if (visible && hasAccess) {
              item.isVisible = true;
            }
          });
        });
      }
    });
  }


  collapseDialog(): void {
    this.menuItems.forEach((x) => {
      x.child_items?.forEach((ci) => {
        ci.isView = false;
      });
    });
    // this.baseUrl = 'CollapseDialog';
  }

  goToUrl(
    url: string,
    label: string,
    newWindow: boolean,
    item,
    subMenuItem,
    event?
  ): void {
    this.menuItems.forEach((x) => {
      if (x.label === item.label) {
        x.child_items?.forEach((ci) => {
          if (!!subMenuItem && ci.label !== subMenuItem.label) {
            ci.isView = false;
          }
        });
      } else {
        x.child_items?.forEach((ci) => {
          ci.isView = false;
        });
      }
    });

    if (url.length > 0) {
      // eslint-disable-next-line
      newWindow ? window.open(url, '_blank') : this.relocateTo(url);
      this.closeMenu(event);
    } else {
      this.baseUrl = label;
    }
  }

  collapse(): void {
    this.baseUrl = null;
  }

  relocateTo(url): void {
    this.router
      .navigateByUrl('/member-profile', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  closeMenu(event?): void {
    event?.stopPropagation();
    this.menuExpanded = false;
    this.checkActiveUrls();
  }

  closeDialogMenu(event?): void {
    event?.stopPropagation();
    this.menuExpanded = false;
  }

  openMenu(): void {
    this.menuExpanded = true;
  }

  isItemVisible$(item: any): Observable<boolean> {
    return item.isVisible === undefined || item.IsVisible === null
      ? of(true)
      : typeof item.isVisible === 'function'
      ? item.isVisible()
      : of(item.isVisible);
  }

  isItemDisabled(item: any): boolean {
    return typeof item.isDisabled === 'function'
      ? item.isDisabled()
      : item.isDisabled;
  }

  hasAccess$(item: any, parent: any): Observable<boolean> {
    return this.isItemVisible$(item).pipe(
      map((visible) => {
        if (!visible) {
          return false;
        }

        const permissions = Array.isArray(item.permission)
          ? item.permission
          : [item.permission];
        if (
          !item.permission ||
          this.accessService.checkUserPermissions(permissions)
        ) {
          parent.notEmpty = true;
        }

        return (
          !item.permission ||
          this.accessService.checkUserPermissions(permissions)
        );
      })
    );
  }

  private getLogoLink(type: string): string {
    let url = getCurrentThemeUrl();
    return type === 'client' ? url : (url += `/${getSiteId()}`);
  }
  openVersionPage(url: string): void {
    this.relocateTo(url);
  }

  private getUmbrellaVersion(environmentConfig: string): any {
    this.versioningApiService
      .getUmbrellaVersion(environmentConfig)
      .subscribe((result: any) => {
        this.umbrellaVersion = result.version;
      });
  }

  openDialog(dialog: OpenDialogInterface, event: Event): void {
    dialog.maximizeFn();
    this.closeMenu(event);
    this.collapseDialog();
    this.closeDialogMenu(event);
  }

}
