import { NgxSpinnerService } from 'ngx-spinner';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private showPermanentSpinner = false;

  constructor(private ngxSpinnerService: NgxSpinnerService) {}

  show(): void {
    if (!this.showPermanentSpinner) {
      this.ngxSpinnerService.show();
    }
  }

  showPermanent(): void {
    this.show();
    this.showPermanentSpinner = true;
  }

  hide(): void {
    if (!this.showPermanentSpinner) {
      this.ngxSpinnerService.hide();
    }
  }

  hidePermanent(): void {
    this.showPermanentSpinner = false;
    this.hide();
  }
}
