<mat-form-field appearance="outline">
  <mat-label>{{placeholder}}</mat-label>
  <input
    matInput
    type="text"
    class="autocomplete-input"
    [matAutocomplete]="auto"
    matAutocompletePosition="below"
    [formControl]="inputControl"
    [placeholder]="placeholder"
    (blur)="onTouched()"
  />
  <!--  TODO: MNADOLSKY In case we need some fields required-->
<!--  <mat-error *ngIf="!inputControl.valid && inputControl.errors?.required">-->
<!--    This field is required-->
<!--  </mat-error>-->
  <div class="icon" *ngIf="icon.length > 0; else plusButton"><i class="icon-{{icon}}"></i></div>
  <ng-template #plusButton>
    <div *ngIf="!showPlusButton && showIcon" class="plus-button" (click)="addInstance()">
      <i class="icon-plus_circle"></i>
    </div>
  </ng-template>
</mat-form-field>
<mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption="true" [displayWith]="displayFn">

<!--  The 1st MatOption is to display a message while the search is running.-->
  <mat-option *ngIf="isSearching; else optionsTemplate" disabled="true">
    <em>Searching...</em>
  </mat-option>

<!--  The 2nd MatOption has a *ngFor directive and works IF results are found.-->
  <ng-template #optionsTemplate>
    <mat-option *ngFor="let option of options" [value]="option" class="provided" (click)="selected()">
      <ng-container *ngIf="rowTemplateDef" [ngTemplateOutlet]="rowTemplateDef"  [ngTemplateOutletContext]="{ $implicit: option}" ></ng-container>
      <ng-container *ngIf="!rowTemplateDef">{{ option.label }}</ng-container>

    </mat-option>
  </ng-template>

<!--  The last MatOption is to display a message when no results are found.-->
  <mat-option *ngIf="!isSearching && noResults && !options.length && isMinLength" value="" disabled="true">
    <b>No search results</b>
  </mat-option>
</mat-autocomplete>
