import { SearchMemberDto } from '@core/services/dto';
import { MemberProfileModel } from '@shared/models/index';

export class SearchMemberModel {
  data: MemberProfileModel[];
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  pageNumber: number;
  totalCount: number;
  totalPages: number;

  constructor(dto: SearchMemberDto) {
   this.data = dto.data;
   this.hasNextPage = dto.hasNextPage;
   this.hasPreviousPage = dto.hasPreviousPage;
   this.pageNumber = dto.pageNumber;
   this.totalCount = dto.totalCount;
   this.totalPages = dto.totalPages;
  }
}
