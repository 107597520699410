import { PersonInfoInterface } from '@shared/models/person.info.interface';

export class SendEmailRequestModel {
  templateId: number;
  to: {
    address: string,
    name: string
  };
  from: {
    address: string;
    name: string
  };
  subject: string;
  body: string;
  header: string;
  data: PersonInfoInterface;
  attachments: number[];

  constructor() {
    this.templateId = null;
    this.to = {
      address: '',
      name: ''
    };
    this.from = {
      address: '',
      name: ''
    };
    this.subject = '';
    this.body = '';
    this.header = '';
    this.data = null;
    this.attachments = null;
  }
}
