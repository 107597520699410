export enum GroupTypeEnum {
  CRMAssignment = 1,
  ECMFolderPermission = 2,
  All = 3,
  RepresentativeRole = 4,
  CRMandRepresentativeRole = 5,
  CRMandECM = 6,
  ECMandRepresentativeRole = 7,
}
export const GroupTypeStringEnum = {
  CRMAssignment: 'CRM Assignment',
  ECMFolderPermission: 'ECM Folder Permission',
  All: 'All',
  RepresentativeRole: 'Representative Role',
  CRMandRepresentativeRole: 'CRM Assignment and Representative Role',
  CRMandECM: 'CRM Assignment and ECM Folder Permission',
  ECMandRepresentativeRole: 'ECM Folder Permission and Representative Role'
};
