<div [className]="addingTaskState ? 'active inner-action' : 'inner-action'">
  <cm-button color="composite"
             class="inner-action-button"
             (click)="clearTaskForm()"
             textPosition="left"
             active="{{addingTaskState ? 'active' : ''}}">
    <i class="icon-task inner-action-icon"></i>Add task<i class="icon-cross"></i>
  </cm-button>
  <form [formGroup]="formTask">
    <div class="inner-fields">
      <div fxLayout>
        <mat-form-field appearance="outline"
                        [style.width.%]="100">
          <mat-label>Template</mat-label>
          <mat-select formControlName="templateId">
            <mat-option *ngFor="let template of taskTemplatesOptions"
                        [value]="template.value"
                        [disabled]="template.disabled">{{template.label}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxLayout>
        <div fxFlex="50"
             class="left-col">
          <mat-form-field appearance="outline"
                          fxFlex="100">
            <mat-label>Task Type</mat-label>
            <mat-select formControlName="type">
              <ng-container *ngFor="let option of taskTypeOptions">
                <mat-option [value]="option.value">{{option.label}}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex="50">
          <mat-form-field appearance="outline"
                          fxFlex="100">
            <mat-label>Priority</mat-label>
            <mat-select formControlName="priority">
              <ng-container *ngFor="let option of taskPriorityOptions">
                <mat-option [value]="option.value">{{option.label}}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div>
        <mat-form-field appearance="outline"
                        fxFlex="100">
          <mat-label>Description</mat-label>
          <textarea matInput
                    placeholder="Task Description Text Here"
                    formControlName="description"></textarea>
        </mat-form-field>
      </div>
      <div fxLayout>
        <div fxFlex="50"
             class="left-col">
          <mat-form-field appearance="outline"
                          fxFlex="100">
            <mat-label>Due Date</mat-label>
            <input matInput
                   [matDatepicker]="picker"
                   formControlName="dueDate"
                   [min]="todayDate">
            <mat-datepicker-toggle matSuffix
                                   [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div fxFlex="50" class="assignedId-container">
          <mat-form-field appearance="outline" fxFlex="100">
            <mat-label>Assigned To</mat-label>
            <input type="text" [(ngModel)]="selectedTaskUser"
             formControlName="assigneeId" matInput 
             placeholder="Assigned To"
              [matAutocomplete]="auto" 
              matAutocompletePosition="auto"
              >
              <mat-icon matSuffix ><i class="icon-search"></i></mat-icon>
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="getOptionText">
              <mat-option *ngFor="let user of taskUsers$ | async" [value]="user"
                [disabled]="user.status === 'Inactive'"
                [ngStyle]="{'color': user.status === 'Inactive' ? 'gray' : 'initial'}">
                {{ user.label}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
      <app-add-email-template *ngIf="isEmailType()"
                              [preselected]="taskEmails"
                              (chosenEmail)="addEmail($event)"></app-add-email-template>
                                <app-add-sms-template *ngIf="isSMSType()" [preselected]="taskSMS"
      (chosenSMS)="addSMS($event)"></app-add-sms-template>
      <app-upload-file #taskAttachments
                       [parentForm]="formTask"
                       [fileType]="FileTypeEnum.Crm"
                       [readOnly]="false"></app-upload-file>
    </div>
  </form>
</div>
