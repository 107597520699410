import { AmortizationTypeStringEnum } from '@shared/enums/amortization-type.enum';
import { LendingDto } from '@core/services/dto';

export class LendingModel {
  accountBalance: number;
  accountCharges: number;
  accountId: number | string;
  accountNumber: string;
  annualFee: number;
  authorizedOverdraft: number;
  availableBalance: number;
  currency: string;
  interestRate: number;
  name: string;
  openedDate: string;
  status: string;
  subType: string;
  type: string;
  purpose: string;
  ownershipType: string;
  riskRating: string;
  revolving: boolean | string;
  maturityDate: string;
  term: {
    termLength: number;
    termLengthType: string;
    termRemainingLength: number;
    termRemainingLengthType: string;
    termStartDate: string;
  };
  mortgageAmount: number;
  amountRepaired: number;
  outstandingBalance: number;
  paymentFrequency: string;
  paymentAmount: number | string;
  currentArrears: string | number;
  dueDate: string;
  overdueDays: string | number;
  branch: string;
  remainingAmortization: string;

  constructor(dto: LendingDto) {
    this.accountBalance = dto.accountBalance || 0;
    this.accountCharges = dto.accountCharges;
    this.accountId = dto.accountId;
    this.accountNumber = dto.accountNumber || '';
    this.annualFee = dto.annualFee || 0;
    this.authorizedOverdraft = dto.authorizedOverdraft || 0;
    this.availableBalance = dto.availableBalance || 0;
    this.currency = dto.currency;
    this.interestRate = dto.interestRate;
    this.name = dto.name || 'Lending';
    this.term = dto.term;
    this.openedDate = dto.openedDate;
    this.status = dto.status;
    this.subType = dto.subType;
    this.type = dto.type;
    this.purpose = dto.purpose;
    this.ownershipType = dto.ownershipType;
    this.riskRating = dto.riskRating;
    this.revolving = dto.revolving ? 'Yes' : 'No';
    this.maturityDate = dto.maturityDate;

    this.mortgageAmount = dto.mortgageAmount || 0;
    this.amountRepaired = dto.amountRepaired || 0;
    this.outstandingBalance = dto.outstandingBalance || 0;
    this.paymentFrequency = dto.paymentFrequency;
    this.paymentAmount = dto.paymentAmount || 0;
    this.currentArrears = dto.currentArrears || 0;
    this.dueDate = dto.dueDate;
    this.overdueDays = dto.overdueDays;
    this.branch = dto.branch;
    this.remainingAmortization = !!dto.amortizationType
    ? `${dto.amortizationDuration} ${AmortizationTypeStringEnum[dto.amortizationType]}`
    : '';
  }
}
