export class PersonModel {
  personId: number;
  firstName: string;
  lastName: string;
  middleName?: string;
  gender: string;
  maritalStatus?: string;
  dateOfBirth?: string;
  dateOfDeath?: string;
  nickname?: string;
  sin: string;

  constructor(obj: object) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        this[prop] = obj[prop];
      }
    }
  }
}
