export enum SaluationEnum {
  Mr = 1,
  Mrs = 2,
  Ms = 3,
  Miss = 4,
  Dr = 5,
  Rev = 6,
  Mx = 7,
  Jr = 8,
  Mdme = 9,
  Mlle = 10,
  M = 11,
  Sr = 12
}
