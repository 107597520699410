<div class="row-element__content" *ngFor="let item of data">
  <div class="record-row-container">
    <div class="header">
      <app-accordion-row-item (iconAction)="onIconHandler($event, item)"
                              [head]="item.head"
                              (clickAction)="emitClick($event)"
                              [hasChildren]="item.data?.length" [tableConfig]="tableConfig"
                              [data]="[item.values]">
      </app-accordion-row-item>
    </div>
    <div class="content" [ngClass]="item.visible ? 'collapse' : 'expand'">
      <app-accordion-row-item [tableConfig]="tableConfig" (clickAction)="emitClick($event)"
                              [data]="item.data"></app-accordion-row-item>
    </div>
  </div>
</div>
