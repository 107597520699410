import { ValidatorFn, AbstractControl } from '@angular/forms';

export class FileValidator {

  static fileMaxSize(maxSize: number): ValidatorFn {

    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== undefined && (isNaN(control.value) || control.value > maxSize)) {
        return {maxSize: true};
      }
      return null;
    };
  }

  static fileExtension(allowedExtensions: Array<string>): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const extension = control?.value?.toLowerCase().split('.').pop();
      if (control.value !== undefined && (!allowedExtensions.includes(extension))) {
        return {extension: true};
      }
      return null;
    };
  }
}
