import { ProductTypeDto } from '@core/services/dto';

export class ProductTypeModel {
  label: string;
  value: number;

  constructor(dto: ProductTypeDto) {
    this.value = dto.id;
    this.label = dto.name;
  }
}

