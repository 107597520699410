import { SiteDetailDto } from '@core/services/dto';
export class SiteDetailModel {
    enableDefaultORInternational: boolean;
    enableCondensedORExpanded: boolean;

    constructor(dto: SiteDetailDto) {
        this.enableDefaultORInternational = dto.enableDefaultORInternational;
        this.enableCondensedORExpanded = dto.enableCondensedORExpanded;
    }
}
