import { AttachmentModel, SearchMemberOptionsModel } from '@shared/models';

export interface TaskCreateInterface {
  type: string;
  status?: string;
  priority: string;
  description: string;
  dueDate: string;
  assigneeId: string;
  emails: number[];
  sms: number[];
  templateId?: number;
  links?: SearchMemberOptionsModel[];
  attachments?: number[];
}
