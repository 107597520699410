import { MemberSyncDto } from '@core/services/dto';

export class MemberSyncModel {
  message: string;
  status: string;
  successSyncDate: string;
  syncDate: string;
  memberNumber?: string;
  memberBranch?: number;
  memberType?: string;
  memberId?: number;
  isProspectInfo?: boolean;

  constructor(dto: MemberSyncDto) {
    this.message = dto.message;
    this.status = dto.status;
    this.successSyncDate = dto.successSyncDate;
    this.syncDate = dto.syncDate;
    this.isProspectInfo = dto.isProspectInfo;
  }
}
