import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '@shared/shared.module';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { SidebarLeftModule } from '@core/components/sidebar-left/sidebar-left.module';
import { MainHeaderModule } from '@core/components/main-header/main-header.module';
import { PlatformLayoutComponent } from '@core/layouts';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MsalModule } from '@azure/msal-angular';
import { MSALConfigFactory } from '@config/msal.config';
import { AuthInterceptor } from '@core/interceptors';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { NgxMaskModule } from 'ngx-mask';
import { NgxEditorModule } from 'ngx-editor';
// import {
//   PERFECT_SCROLLBAR_CONFIG,
//   PerfectScrollbarConfigInterface,
//   // PerfectScrollbarModule
// } from 'ngx-perfect-scrollbar';
import { CustomMomentDateAdapter } from '@shared/utils/custom-moment-date-adapter';
import { MatDialogModule } from '@angular/material/dialog';


// const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
//   suppressScrollX: true
// };

@NgModule({
  declarations: [
    AppComponent,

    // Main layouts
    PlatformLayoutComponent,
  ],
  imports: [
    BrowserModule,
    MainHeaderModule,
    MatSnackBarModule,
    SidebarLeftModule,
    BrowserAnimationsModule,
    SharedModule,
    // PerfectScrollbarModule,
    NoopAnimationsModule,
    FlexLayoutModule,
    NgxEditorModule,
    HttpClientModule,
    MsalModule.forRoot(MSALConfigFactory(), null, null),
    MatDatepickerModule,
    MatNativeDateModule,
    NgxSpinnerModule,
    MatDialogModule,
    NgxMaskModule.forRoot(),
    AppRoutingModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: DateAdapter, useClass: CustomMomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'DD-MM-YYYY',
        },
        display: {
          dateInput: 'DD-MM-YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'YYYY',
        },
      },
    }
    // {
    //   provide: PERFECT_SCROLLBAR_CONFIG,
    //   useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
