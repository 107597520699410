import { ProductDto } from '@core/services/dto';

export class ProductModel {
  label: string;
  value: number;
  active: boolean;
  type: {
    id: number,
    name: string
  };

  constructor(dto: ProductDto) {
    this.label = dto.name;
    this.value = dto.id;
    this.active = dto.active;
    this.type = dto.type;
  }
}

