import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GlobalStateService } from '@core/services/global.state.service';
import { CreateConversationStateService } from '@shared/components/conversation/create-conversation.state.service';
import { ConversationType } from '@shared/configs/conversation-types.config';

@Component({
  selector: 'app-create-conversation-wrapper',
  templateUrl: './create-conversation-wrapper.component.html'
})
export class CreateConversationWrapperComponent implements OnInit {
  @Output() closeDialog: EventEmitter<any> = new EventEmitter();
  @Output() conversationTypeChange: EventEmitter<string> = new EventEmitter();
  @Output() formChanged: EventEmitter<boolean> = new EventEmitter();
  conversationType = ConversationType.conversation;

  ConversationType = ConversationType;
  constructor(private globalState: GlobalStateService, private state: CreateConversationStateService) { }

  ngOnInit(): void {
    this.globalState.getProductTypes();
    this.globalState.getConcernTypes();
    this.globalState.getCampaigns();
    this.state.getTaskTemplates();
  }

  conversationTypeChanged(type: ConversationType): void {
    this.conversationType = type;
    this.conversationTypeChange.emit(type);
  }

  onCloseDialog(): void {
    this.closeDialog.emit();
  }

  onFormValueChanged(event: boolean): void {
    this.formChanged.emit(event);
  }
}
