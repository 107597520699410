import { Injectable } from '@angular/core';
import { ApiClient } from '@core/services/api-client.http.service';
import { getCurrentThemeUrl } from '@config/org.config';
import { ThemeUrls } from '@core/constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'any'
})
export class ThemeBuilderApiService {

  constructor(private api: ApiClient) {
  }

  getCustomStyles(): Observable<string> {
    return this.api.get<any>(ThemeUrls.getStyle('CustomStyles'));
  }

  getDefaultStyles(type): Observable<string> {
    return this.api.get<any>(ThemeUrls.getStyle(type));
  }

  updateThemeStyles(styles): Observable<string> {
    const request = {
      stylesType: 'CustomThemeColors',
      content: styles == null ? '' : styles,
    };
    return this.api.put(ThemeUrls.themeStyles, request);
  }

  updateCustomCss(styles): Observable<string> {
    const request = {
      stylesType: 'CustomStyles',
      content: styles == null ? '' : styles,
    };
    return this.api.put(ThemeUrls.themeStyles, request);
  }
}
