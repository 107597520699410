import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AnnouncementUrls } from '@core/constants/endpoints.constants';
import { ApiClient } from '@core/services/api-client.http.service';
import { AnnouncementRequestDto } from '@shared/models/requests/announcement.request.dto';
import { AnnouncementsDto, PaginatedResponseDto } from '@core/services/dto';

@Injectable({
  providedIn: 'any'
})
export class AnnouncementsApiService {

  constructor(private api: ApiClient) {
  }

  getAll(request, isUser): Observable<PaginatedResponseDto<AnnouncementsDto>> {
    const url = isUser ? AnnouncementUrls.announcementUser : AnnouncementUrls.announcements;
    return this.api.get<PaginatedResponseDto<AnnouncementsDto>>(url, request);
  }

  create(request: AnnouncementRequestDto): Observable<number> {
    return this.api.post<any>(AnnouncementUrls.announcements, request);
  }

  update(id: number, request: AnnouncementRequestDto): Observable<AnnouncementsDto> {
    return this.api.put<AnnouncementsDto>(AnnouncementUrls.announcement(id), request);
  }

  delete(id: number): Observable<any> {
    return this.api.delete<any>(AnnouncementUrls.announcement(id));
  }

  view(id: number): Observable<AnnouncementsDto> {
    return this.api.get<AnnouncementsDto>(AnnouncementUrls.announcement(id));
  }

}
