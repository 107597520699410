import { GlobalStateService } from '@core/services/global.state.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NoteModel, SearchAccountOptionsModel } from '@shared/models';
import { NoteApiService } from '@core/services/api.services';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'any' })

export class CreateNoteStateService {
  constructor(
    private globalState: GlobalStateService,
    private service: NoteApiService,
  ) {

  }

  member$(): Observable<any> {
    return this.globalState.memberProfile;
  }

  preselectedAccount$(): Observable<SearchAccountOptionsModel[]> {
    return this.globalState.preselectedAccount;
  }

  setNote(note: NoteModel): Observable<any> {
    return this.service.setNote(note)
    .pipe(
      tap(() => this.globalState.noteUpdated.next(true))
    );
  }
}
