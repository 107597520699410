import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AccessService } from '@core/services/access.service';
import { AuthService } from '@core/services/auth';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuardService  {

  constructor(
    private router: Router,
    private accessService: AccessService,
    private auth: AuthService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const user = this.auth.permissions;
    const isCrm = this.auth.isCrm;
    const isAosAdmin = this.auth.isAosAdmin;

    const permissions = Array.isArray(route.data.permission)
      ? route.data.permission
      : [route.data.permission];

    if (!!user && user.length && this.accessService.checkUserPermissions(permissions) && (isCrm || isAosAdmin)) {
      return true;
    }
    return this.router.parseUrl('dashboard'); // routing to home in case of user with no access
  }
}
