import { EmailTemplateDto } from '@core/services/dto';

export class SearchEmailTemplatesOptionsModel {
  label: string;
  value: string;
  ref: number;
  type: string;

  constructor(dto: EmailTemplateDto) {
    this.label = `${dto.name} - ${dto.subject}` ;
    this.value = dto.subject;
    this.ref = dto.id;
    this.type = dto.type;
  }
}
