export enum PhoneTypeEnum {
  Home = 1,
  Mobile = 2,
}

export const PhoneTypeStringEnum = {
  Home: 'Home',
  Mobile: 'Mobile',
};

export enum BusinessPhoneTypeEnum {
  Home = 1,
  Mobile = 2,
  Business = 3
}

export const BusinessPhoneTypeStringEnum = {
  Home: 'Home',
  Mobile: 'Mobile',
  Business: 'Business'
};
