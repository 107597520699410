import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TableColumnModel } from '@shared/components/custom-table/models/TableColumn.model';

@Component({
  selector: 'app-record-column-item',
  templateUrl: './record-column.component.html',
  styleUrls: ['./record-column.component.scss'],
})
export class RecordColumnComponent {
  @Input() col: any;
  @Input() head: any;
  @Input() tableConfig: TableColumnModel[];
  @Output() clickAction: EventEmitter<any> = new EventEmitter();
  @Output() iconAction = new EventEmitter<boolean>();
  visible: boolean;

  public emitClick(col): void {
    this.clickAction.emit(col);
  }

  public onIconClick(): void {
    this.visible = !this.visible;
    this.iconAction.emit(this.visible);
  }

}
