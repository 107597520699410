export enum OfferStatusEnum {
  Active = 1,
  Inactive = 2,
  Fulfilled = 3,
  Closed = 4,
  Expired = 5,
  Deleted = 6,
}

export const OfferStatusStringEnum = {
  Active: 'Active',
  Inactive: 'Inactive',
  Fulfilled: 'Fulfilled',
  Expired: 'Expired',
  Closed: 'Closed',
  Deleted: 'Deleted',
};
