<div [className]="addingInteractionState ? 'inner-action active' : 'inner-action'">
  <cm-button color="composite"
             class="inner-action-button"
             (click)="clearFormInteraction()"
             textPosition="left"
             active="{{addingInteractionState ? 'active' : ''}}">
    <i class="icon-interaction inner-action-icon"></i>Add interaction<i class="icon-cross"></i>
  </cm-button>
  <form [formGroup]="formInteraction">
    <div class="inner-fields">
      <div fxLayout>
        <div fxFlex="60"
             class="left-col">
          <mat-form-field fxFlex="100"
                          appearance="outline">
            <mat-label>Subject</mat-label>
            <input matInput
                   placeholder="Subject"
                   formControlName="subject"
                   min="3">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline">
            <mat-label>Communication Type</mat-label>
            <mat-select formControlName="communicationType">
              <ng-container *ngFor="let option of communicationTypeOptions">
                <mat-option [value]="option.value">{{option.label}}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div>
        <mat-form-field appearance="outline"
                        fxFlex="100">
          <mat-label>Description</mat-label>
          <textarea matInput
                    placeholder="Description Text Here"
                    formControlName="description"></textarea>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
