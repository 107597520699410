import { NoteDto } from '@core/services/dto/note.dto';
import { formatDateSimple } from '@shared/utils';
import { LinkModel } from '@shared/models/link.model';
import { AttachmentModel } from '@shared/models/attachment.model';
import { UserDto } from '@core/services/dto';


export class NoteModel {
  id: number;
  noteId: number;
  description: string;
  created?: string;
  createdBy?: UserDto | string;
  links: LinkModel[];
  stickyDueDate: Date | string;
  attachments: AttachmentModel[];
  sticky: string;
  memberLinks: LinkModel[];

  constructor(dto: NoteDto) {
    this.id = dto.noteId;
    this.noteId = dto.noteId;
    this.description = dto.description;
    this.created = formatDateSimple(dto.created);
    this.createdBy = `${dto.createdBy?.firstName} ${dto.createdBy?.lastName}`;
    this.links = dto.links;
    this.sticky = dto.stickyDueDate ? formatDateSimple(dto.stickyDueDate) : dto.stickyDueDate;
    this.stickyDueDate = dto.stickyDueDate ? new Date(dto.stickyDueDate) : null;
    this.attachments = dto.attachments;
    this.memberLinks = dto.links.filter(link => link.type === 'Member');
  }

}
