import { Injectable } from '@angular/core';
import { SMSTemplatesApiService } from '@core/services/api.services/sms-templates-api.service';
import { SearchEmailTemplatesOptionsModel } from '@shared/models';
import { SMSTemplateSearchRequestDto } from '@shared/models/requests/sms-template-search.request.dto';
import { SearchSMSTemplatesOptionsModel } from '@shared/models/search.sms-templates.options.model';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AddSmsTemplateStateService {
  private templates: Subject<SearchSMSTemplatesOptionsModel[]> = new Subject<SearchSMSTemplatesOptionsModel[]>();

  constructor(private service: SMSTemplatesApiService) {
  }


  templates$(): Observable<SearchSMSTemplatesOptionsModel[]> {
    return this.templates.asObservable();
  }

  getTemplates(name: string, excludeIds: number[]): void {
    const request: SMSTemplateSearchRequestDto = {
      type: 'CRM',
      excludeIds,
      name,
      pageSize: 3,
      sortBy: 'name',
      sort: 'asc',
      templateType: 'SMS'
    };
    this.service.searchTemplates(request)
    .pipe(map(res => res.data.map(item => new SearchSMSTemplatesOptionsModel(item))))
    .subscribe(data => this.templates.next(data));
  }

}

