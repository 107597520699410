import { Injectable } from '@angular/core';
import { ProspectApiService } from '@core/services/api.services/prospect-api.service';
import { UserApiService } from '@core/services/api.services/user-api.service';
import { PaginatedResponseDto } from '@core/services/dto';
import { GlobalStateService } from '@core/services/global.state.service';
import { ProvinceModel } from '@shared/models';
import { ProspectCreate } from '@shared/models/prospect-create';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CreateProspectStateService {

  constructor(
    private service: ProspectApiService,

  ) { }

  createProspect(prospect: ProspectCreate): Observable<any> {
    return this.service.createProspect(prospect);
  }

  getProvinces(): Observable<PaginatedResponseDto<ProvinceModel>> {
    return this.service.getProvinces();
  }

}


