import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { GlobalStateService } from '@core/services/global.state.service';
import { getCurrentThemeUrl, getSiteId } from '@config/org.config';
import { addThemeCss } from '@shared/utils';
import { OpenedDialogService } from '@core/services/dialog/opened-dialog.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-platform',
  templateUrl: './platform-layout.component.html',
  styleUrls: ['./platform-layout.component.scss']
})
export class PlatformLayoutComponent implements OnInit {
  constructor(
    private router: Router,
    private globalStateService: GlobalStateService,
    private openedDialog: OpenedDialogService,
    private dialog: MatDialog
  ) { }


  ngOnInit(): void {
    this.globalStateService.themeBuilderType$
      .pipe(
        take(1)
      )
      .subscribe(type => {
        const link = this.getLink(type);

        addThemeCss(`${link}/styles/custom_theme_colors.css`);
        addThemeCss(`${link}/styles/custom.css`);
      });

    this.checkOpenedDialog();
  }

  private getLink(type: string): string {
    let url = getCurrentThemeUrl();
    return type === 'client' ? url : url += `/${getSiteId()}`;
  }

  private checkOpenedDialog(): void {
    this.router.events.subscribe(() => {
      this.dialog.openDialogs.forEach(dialog => {
        const isMinimized = this.openedDialog.allOpenDialog().find(xDialog => xDialog.id === dialog.id);
        if (!isMinimized) {
          dialog.close();
        }
      });
    });
  }
}
