import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmButtonComponent } from '@shared/components/cm-button/cm-button.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
    CmButtonComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule
  ],
  exports: [
    CmButtonComponent
  ],
})
export class CmButtonModule {
}
