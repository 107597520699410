import { AmortizationTypeStringEnum } from '@shared/enums/amortization-type.enum';
import { LendingDto } from '@core/services/dto';


export class MortgageModel {
  accountNumber: string;
  mortgageAmount: number | string;
  amountRepaired: number | string;
  outstandingBalance: number;
  paymentFrequency: string;
  paymentAmount: number | string;
  remainingAmortization: string;
  dueDate: string;
  currentArrears: string | number;
  overdueDays: number | string;
  status: string;
  subType: string;
  type: string;
  branch: string;
  openedDate: string;
  ownershipType: string;
  purpose: string;
  maturityDate: string;
  revolving: string;
  riskRating: string;
  termLength: string;
  termRemaining: string;
  termStartDate: string;
  name: string;

  constructor(dto: LendingDto) {
    this.accountNumber = dto.accountNumber;
    this.mortgageAmount = dto.mortgageAmount || 0;
    this.amountRepaired = dto.amountRepaired || 0;
    this.outstandingBalance = dto.outstandingBalance || 0;
    this.paymentFrequency = dto.paymentFrequency;
    this.paymentAmount = dto.paymentAmount || 0;
    this.remainingAmortization = !!dto.amortizationType
      ? `${dto.amortizationDuration} ${AmortizationTypeStringEnum[dto.amortizationType]}`
      : '';
    this.dueDate = dto.dueDate;
    this.currentArrears = dto.currentArrears || 0;
    this.overdueDays = dto.overdueDays || '';
    this.status = dto.status;
    this.type = dto.type;
    this.subType = dto.subType;
    this.branch = dto.branch;
    this.openedDate = dto.openedDate;
    this.ownershipType = dto.ownershipType;
    this.purpose = dto.purpose;
    this.maturityDate = dto.maturityDate;
    this.revolving = 'Yes';
    this.riskRating = dto.riskRating;
    this.termLength = (dto.term?.termLength && dto.term?.termLengthType) ?
      `${dto.term.termLength} ${dto.term.termLengthType}` : '';
    this.termRemaining = (dto.term?.termRemainingLength && dto.term?.termRemainingLengthType) ?
      `${dto.term.termRemainingLength} ${dto.term.termRemainingLengthType}` : '';
    this.termStartDate = dto.term?.termStartDate;
    this.name = dto.name || 'Lending';
  }
}
