import { Injectable } from '@angular/core';
import { RecentActivityUrls } from '@core/constants';
import { Observable } from 'rxjs';
import { RecentActivitySummaryDto } from '@core/services/dto/recent-activity-summary.dto';
import { RecentActivityMemberSummaryDto } from '@core/services/dto/recent-activity-member-summary.dto';
import { ApiClient } from '@core/services/api-client.http.service';

@Injectable({
  providedIn: 'any'
})
export class RecentActivityApiService {

  constructor(private api: ApiClient) {
  }

  getApiSummary(): Observable<RecentActivitySummaryDto>  {
    return this.api.get<RecentActivitySummaryDto>(RecentActivityUrls.summary);
  }

  getMemberSummary(): Observable<RecentActivityMemberSummaryDto> {
    return this.api.get<RecentActivityMemberSummaryDto>(RecentActivityUrls.memberSummary);
  }

}
