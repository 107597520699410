import { AuthService } from '@core/services/auth';
import { MsalService } from '@azure/msal-angular';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { getCurrentResetPasswordAuthority, getCurrentResetPasswordPolicyName } from '@config/msal.config';
import { AuthenticationResult, RedirectRequest } from '@azure/msal-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isOnline: boolean;

  constructor(
    private title: Title,
    private msalService: MsalService,
    private authService: AuthService
  ) {
    this.isOnline = navigator.onLine;
  }

  ngOnInit(): void {
    this.title.setTitle('Team Portal | ASAPP OXP');

    this.msalService.handleRedirectObservable().subscribe({
      next: (result: AuthenticationResult) => {
        if (!!result) {
          if ((result.idTokenClaims as any).tfp === getCurrentResetPasswordPolicyName()) {
            this.msalService.logout().subscribe(() => { });
          }
          else {
            if (!localStorage.getItem('inner_token')) {
              this.authService.refreshInnerLogin(true).subscribe(() => { });
            }
          }
        }
        else {
          if (!this.authService.checkAccountLogin()) {
            this.authService.login();
          }
          else if (!this.authService.getInnerToken()) {
            this.authService.refreshInnerLogin(true).subscribe(() => { });
          } else {
            this.authService.getUserName();
          }
        }
      },
      error: (error) => {
        if (error && error.errorMessage.includes('AADB2C90118')) {
          //   localStorage.setItem('forgot_password', 'true');
          this.msalService.loginRedirect({ authority: getCurrentResetPasswordAuthority() } as RedirectRequest);
        }
      }
    });
  }
}
