import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { SidebarLeftComponent } from './sidebar-left.component';
import { RouterModule } from '@angular/router';
import { ExtendedModule, FlexLayoutModule } from '@ngbracket/ngx-layout';
import { SharedModule } from '@shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  declarations: [
    SidebarLeftComponent,
  ],
    imports: [
        CommonModule,
        SharedModule,
        BrowserModule,
        MatListModule,
        MatIconModule,
        RouterModule,
        ExtendedModule,
        FlexLayoutModule,
    ],
  exports: [
    SidebarLeftComponent
  ],
})
export class SidebarLeftModule {

}
